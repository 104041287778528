
.login_wrapper{
	// float: left;
	// width: 100%;
	// height: 100vh;
	// display: flex;
	// align-items: center;
	// justify-content: center;
	// background-image: url('/images/login-left.png'), url('/images/login-right.png'), url('/images/login-bottom.png');
	// background-position: left top 115%, right top, bottom -5% right 15%;
    // background-position: left top 115%, right top, bottom -11% right;
	// background-repeat: no-repeat, no-repeat, no-repeat;
	// background-size: 35%, 60%, 50%;

	float: left;
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background: url('/images/userpag-bg.png') no-repeat;
	background-position: center center;
	background-size: cover;


	.login_form{
		float: left;
		width: 100%;
		.form_input_fields{
			float: left;
			width: 100%;
			height: 40px;
			font-size: 16px;
			line-height: 24px;
			background: #FFFFFF;
			color: #333;
			border: 1px solid #D9D9D9;
			box-sizing: border-box;
			border-radius: 2px;
			-webkit-border-radius: 2px;
			-moz-border-radius: 2px;
		}
		.rememberlogin_form{
			float: left;
			width: 100%;
		}
		.rememberme_text{
			font-size: 14px;
			font-weight: 400;
			color: #000000;
		}	
		.login_form_forgot{
			float: left;
			width: 100%;
			margin-top: 1em;
			font-size: 14px;
			font-weight: 400;
			color: #1890FF;
		}
		.login_form_submit_btn{
			float: left;
			width: 100%;
			border:medium none;
			height: 40px;
			background: #1890FF;
			font-size: 16px;
			line-height: 24px;
			color: #FFFFFF;
			text-align: center;
			border-radius: 2px;
			-webkit-border-radius: 2px;
			-moz-border-radius: 2px;
		}
		.text_after_loginbtn{
			float: left;
			width: 100%;
			font-size: 16px;
			font-weight: normal;
			line-height: 24px;
			color: #1890FF;
			text-align: center;
			a{
				text-decoration: underline;
			}
		}
		.forminput_wrappers{
			float: left;
			width: 100%;
			position: relative;
			input[type='text']{
				position: relative;
				padding-left: 35px;
			}
			img{
				position: absolute;
				left: 15px;
				top: 14px;
				z-index: 1;
			}
		}

		.formpassword_wrappers{
			float: left;
			width: 100%;
			position: relative;
			input[type='password']{
				position: relative;
				padding-left: 35px;
			}
			img{
				position: absolute;
				left: 15px;
				top: 11px;
				z-index: 1;
			}
		}
	}
	.login_form_logo{
		width: 100%;
		text-align: center;
		margin-bottom: 5em;
		img{
			width: 40%;
		}
	}


}

// #normal_login{
// 	.ant-checkbox-wrapper{
// 		span{
// 			font-size: 14px;
// 			font-weight: 400;
// 			color: #f00;
// 		}
// 	}
// }

input[type='text']::-webkit-input-placeholder,
input[type='password']::-webkit-input-placeholder,
input[type='email']::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	font-size: 16px;
	line-height: 24px;
  color: rgba(0, 0, 0, 0.25);
}
input[type='text']::-moz-placeholder,
input[type='password']::-moz-placeholder,
input[type='email']::-moz-placeholder { /* Firefox 19+ */
  font-size: 16px;
	line-height: 24px;
  color: rgba(0, 0, 0, 0.25);
}
input[type='text']:-ms-input-placeholder,
input[type='password']:-ms-input-placeholder,
input[type='email']:-ms-input-placeholder { /* IE 10+ */
  font-size: 16px;
	line-height: 24px;
  color: rgba(0, 0, 0, 0.25);
}
input[type='text']:-moz-placeholder,
input[type='password']:-moz-placeholder,
input[type='email']:-moz-placeholder { /* Firefox 18- */
  font-size: 16px;
	line-height: 24px;
  color: rgba(0, 0, 0, 0.25);
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.responsive_img{
	max-width: 100%;
	height: auto;
}


.formusericon{
	background: url('/public/images/login-user.svg') no-repeat;
	height: 40px;
	width: 40px;
}

// .login_form_container {
// 	max-width: 300px;
// }
// .login_form_logo {
// 	margin-bottom: 40px;
// }
// .login_form_forgot {
// 	float: right;
// }


/*responsive layout*/
@media (min-width: 1601px) and (max-width: 1680px) {}
@media (min-width: 1441px) and (max-width: 1600px) {}
@media (min-width: 1367px) and (max-width: 1440px) {}
@media (min-width: 1281px) and (max-width: 1366px) {
	// .login_wrapper{
	// 	background-position: left top -106px, right top, bottom 0 right 218px;
	// 	background-size: 485px, 815px, 635px;
	// }
}
@media (min-width: 1024px) and (max-width: 1280px) {
// 	.login_wrapper{
//     background-size: 55% !important;
//     background-position: left -72px top, right top, bottom 0% right;
//   }
}
@media (min-width: 768px) and (max-width: 1023px) {
//   .login_wrapper{
//     background-size: 55% !important;
//     background-position: left -72px top, right top, bottom 0% right;
//   }
}
/*mobile vertical layout*/
@media only screen and (max-width: 767px) {
	.login_wrapper{
    background-size: 55% !important;
    background-position: left -72px top, right top, bottom 0% right;
	}
	.login_form_logo{
		width: 100%;
		text-align: center;
		margin-bottom: 5em;
		img{
			width: 80px !important;
		}
	}
}