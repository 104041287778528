//$primary-color: #4646A4;
$secondary-color: #FFAE4D;
$white-color: #FFFFFF;
$menulink-color: #595959;


$colors:(
  gray: #ACACAC,
  red: #F5222D,
  green: #52C41A,
  orange: #FF9A24,
)