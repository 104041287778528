@import "~antd/dist/antd.css";
@import "variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,300&display=swap");
@import "custom.scss";
/*
Thin 100
Light 300
Light 300 italic
Regular 400
Medium 500
Bold 700
Black 900
*/

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eeeeee;
}

@media screen and (max-width: "766px") {

  #root,
  .dashboard-bailiff {
    display: block !important;
  }
}

.GridItem {
  @media screen and (max-width: "1279px") {
    margin-top: 1%;
  }

  @media screen and (max-width: "310px") {
    margin-top: 4%;
  }
}

@mixin tablebullet {
  float: left;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
}

responsive_icon_cards {
  background: red;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

img {
  max-width: 100%;
}

.content_container {
  padding: 16px 24px;
}

// Antd Color
/*.ant-btn-primary {
  background: $primary-color;
  border-color: $primary-color;

  &:hover,
  &:focus {
    background: $primary-color;
    border-color: $primary-color;
    opacity: 0.8;
  }
}*/

li.ant-menu-item-selected {
  background-color: #e6f7ff !important;
  border-right: 3px solid #1890ff !important;
}

/*.ant-pagination-item-active,
.ant-pagination-item-active a {
  color: $primary-color;
  border-color: $primary-color;
}*/

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #fff;

  li {
    color: #595959;

    &:hover {
      color: #595959 !important;
      background: #e6f7ff;
      border-right: 3px solid #1890ff !important;
    }
  }
}

.ant-layout {
  .ant-layout-header {
    padding: 0.5em 1em 0.5em 1em;
    height: auto;
    line-height: 35px;
    background-color: #fff !important;
    box-shadow: 0px 2px 6px rgba(0, 21, 41, 0.12);
  }

  .profile-dropdown {
    float: right;
    font-size: 14px;
    font-weight: 400;
    color: #595959;

    img {
      margin-right: 10px;
      width: 40px;
      height: 40px;
      object-fit: cover;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
    }
  }

  .ant-layout-has-sider {
    margin-top: 3.5em;

    .ant-layout-content {
      .ant-breadcrumb {
        .ant-breadcrumb-link {
          font-size: 12px;
          font-weight: 400;
          color: #878787;
        }
      }
    }
  }
}

.ant-layout-sider {
  .ant-layout-sider-children {
    height: calc(100vh - -30px);

    .sidmenu_top_profile {
      float: left;
      width: 100%;
      padding: 2em 1em;

      img {
        float: left;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        object-fit: cover;
        width: 22px;
        height: 22px;
      }

      span {
        font-size: 16px;
        font-weight: 500;
        padding-left: 8px;
        float: left;
        width: 85%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .ant-menu-dark {
      .ant-menu-item {
        font-size: 14px;
        font-weight: 500;
        color: #000000;
      }

      .ant-menu-submenu {
        font-size: 14px;
        font-weight: 500;
        color: #000000;

        .ant-menu-submenu-title {
          .ant-menu-submenu-arrow {
            &:after {
              background: #979797;
            }

            &:before {
              background: #979797;
            }
          }

          &:hover {
            color: #1890ff !important;
          }
        }
      }
    }
  }
}

.constats-table {
  .ant-table-content {
    .table-gray-circle {
      @include tablebullet;
      background: map-get($colors, gray);
    }

    .ant-table-tbody {
      tr {
        td:nth-child(2) {
          min-width: 150px;
        }

        td:nth-child(3) {
          min-width: 100px;
        }

        td:nth-child(5) {
          width: 150px;
        }

        td:nth-child(6) {
          width: 200px;

          span {
            svg {
              margin: 0px 10px;
            }
          }
        }

        td:last-child {
          width: 100px;
          text-align: center;

          span.anticon.anticon-mail {
            float: none;
            display: inline-block;
            margin: 0px 15px;
          }
        }
      }

      tr {
        td {
          span {
            svg {
              // font-size: 18px;
            }
          }

          a {
            color: rgba(0, 0, 0, 0.85);
            text-decoration: none;
          }
        }
      }
    }
  }
}

.gutter-box {
  padding: 8px 0;
  background: #00a0e9;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  border: 7px solid #ccc;
  box-shadow: inset 0 0 2.5px 2px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb {
  /*background:linear-gradient(45deg, #06dee1, #79ff6c);*/
  background: linear-gradient(transparent, #666);
  border-radius: 3px;
  height: 100px;
}

.constats-table {
  .nested-table {
    .ant-table {
      margin: 0px !important;

      .ant-table-container {
        table {
          .ant-table-tbody {
            td:first-child {
              width: 47%;
              height: 350px;
              text-align: left;
              margin: 10px 15px;
              float: left;
              border: 1px solid #e9e9e9;
              border-radius: 2px;
              -webkit-border-radius: 2px;
              -moz-border-radius: 2px;

              a {
                border: 1px solid #40a9ff;
                color: #40a9ff;
                font-size: 14px;
                font-weight: 400;
                min-width: 160px;
                text-align: center;
                padding: 3px;
                float: right;
                border-radius: 4px;
                -webkit-border-radius: 4px;
                -moz-border-radius: 4px;

                span {
                  margin-right: 8px;
                }
              }
            }

            td:last-child {
              width: 47%;
              height: 350px;
              overflow-y: scroll;
              overflow-x: hidden;
              text-align: left;
              margin: 10px 15px;
              float: left;
              border: 1px solid #e9e9e9;
              border-radius: 2px;
              -webkit-border-radius: 2px;
              -moz-border-radius: 2px;

              a {
                border: 1px solid #40a9ff;
                color: #40a9ff;
                font-size: 14px;
                font-weight: 400;
                min-width: 160px;
                text-align: center;
                padding: 3px;
                float: right;
                border-radius: 4px;
                -webkit-border-radius: 4px;
                -moz-border-radius: 4px;

                span {
                  margin-right: 8px;
                }
              }
            }

            .tabletoggle-section {
              float: left;
              width: 100%;

              .ant-space-align-center {
                float: left;
                width: 100%;
                border-bottom: 1px solid #e9e9e9;
                padding-bottom: 8px;
                margin-bottom: 15px;

                .ant-space-item {
                  width: 50%;

                  h2 {
                    font-size: 18px;
                    font-weight: 700;
                    color: #595959;
                  }
                }
              }

              .tabletoggle-section-list {
                .tabletoggle-section-info {
                  float: left;
                  width: 100%;

                  h2 {
                    font-size: 14px;
                    font-weight: 700;
                    float: left;
                    width: 90%;
                    //color: rgba($color: #000000, $alpha: 1.0);
                    color: rgba(0, 0, 0, 0.67);
                  }

                  .table-audio-icons {
                    float: right;
                    width: 8%;

                    ul {
                      float: left;
                      width: 100%;
                      padding: 0px;
                      margin: 0px;
                      list-style: none;

                      li {
                        float: left;

                        svg {
                          width: 20px;
                        }
                      }
                    }
                  }
                }

                .table-autio-date {
                  ul {
                    float: left;
                    width: 100%;
                    list-style: none;
                    padding: 0px;
                    margin: 0px;

                    li {
                      float: left;
                      width: auto;
                      margin-right: 30px;
                      font-size: 12px;
                      font-weight: 400;
                      color: rgba(0, 0, 0, 0.65);
                    }
                  }
                }
              }

              .table-statu-fact {
                float: left;
                width: 100%;
                margin-top: 3em;

                h2 {
                  float: left;
                  width: 100%;
                  font-size: 18px;
                  font-weight: 700;
                  color: #595959;
                  padding-bottom: 10px;
                  margin-bottom: 10px;
                  border-bottom: 1px solid #e9e9e9;
                }

                .table-statu-fact-info {
                  .title {
                    font-size: 14px;
                    width: 100%;
                    float: left;
                    font-weight: 400;
                    color: rgba(0, 0, 0, 0.65);
                    text-align: center;
                    // margin-bottom: 10px;
                    padding: 0px;

                    .ant-form-item {
                      margin-bottom: 0px;
                    }

                    span {
                      font-size: 12px;
                      margin-top: 3px;
                      float: left;
                      font-weight: 400;
                      color: rgba(0, 0, 0, 0.65);
                    }
                  }

                  .table-info {
                    font-size: 14px;
                    font-weight: 400;
                    color: rgba(0, 0, 0, 0.65);
                    text-align: center;
                    padding: 4px;
                    border: 1px solid #e9e9e9;
                  }

                  .gutter-row {
                    padding: 0px !important;
                  }
                }

                .envoyer-btn {
                  width: 100%;
                  height: 32px;
                  background: #40a9ff;
                  border-radius: 4px;
                  -webkit-border-radius: 4px;
                  -moz-border-radius: 4px;
                  border: medium none;
                  font-size: 14px;
                  font-weight: 400;
                  color: #ffffff;
                  // margin-top: 3em;
                }

                .nopadding {
                  .gutter-row {
                    padding: 5px 0px 5px 0px !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.tabletoggle-section {
  float: left;
}

.ant-checkbox+span {
  padding-right: 0px;
  padding-left: 10px;
  font-size: 12px;
  font-weight: 400;
}

.ant-checkbox-inner {
  border: 2px solid orange;
}

// .ant-checkbox-inner{
//   width: 12px;
//   height: 12px;
// }
.table-dropdown {
  border: 1px solid #d9d9d9;
  padding: 7px 0px 7px 7px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}

.okbtn {
  border: 1px solid #d9d9d9;
  padding: 5px;
  word-break: keep-all;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}

.orangeBorder {
  border: 1px solid orange;
}

.ant-pagination {
  // display: none;
}

.ant-pagination-options {
  display: none;
}

.dashboard-wrapper {
  float: left;
  width: 100%;
  background: transparent;

  .dashboard-left-section {
    float: left;
    width: 100%;
  }

  .dashboard-right-section {
    float: left;
    width: 100%;

    // background-color: #f00;
    // margin-top: 10em;
    .dashboard-top-one {
      float: left;
      width: 100%;
      background: #ffffff;
      text-align: center;
      padding: 0em 1em;
      border-bottom: 3px solid #a96f9f;
      border-radius: 2px 2px 2px 2px;
      -webkit-border-radius: 2px 2px 2px 2px;
      -moz-border-radius: 2px 2px 2px 2px;

      .ant-row {
        margin: 0px !important;

        .dashboard-icon-center {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 58px;
        }

        p {
          margin-bottom: 0px;
          text-align: right;
          font-size: 12px;
          color: #595959;
          margin-top: 8px;
        }

        h2 {
          margin-bottom: 0px;
          text-align: right;
          font-size: 24px;
          color: #595959;
          font-weight: 500;
          margin-top: 0px;
        }

        .ant-col {
          padding: 0px !important;
        }
      }
    }

    .dashboard-top-two {
      float: left;
      width: 100%;
      background: #ffffff;
      text-align: center;
      padding: 0em 1em;
      border-bottom: 3px solid #019bcd;
      border-radius: 2px 2px 2px 2px;
      -webkit-border-radius: 2px 2px 2px 2px;
      -moz-border-radius: 2px 2px 2px 2px;

      .ant-row {
        margin: 0px !important;

        .dashboard-icon-center {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 58px;
        }

        p {
          margin-bottom: 0px;
          text-align: right;
          font-size: 12px;
          color: #595959;
          margin-top: 8px;
        }

        h2 {
          margin-bottom: 0px;
          text-align: left;
          font-size: 24px;
          color: #595959;
          font-weight: 500;
          margin-top: 0;
        }

        .ant-col {
          padding: 0px !important;
        }
      }
    }

    .dashboard-top-five {
      float: left;
      width: 100%;
      background: #ffffff;
      text-align: center;
      padding: 0em 1em;
      border-bottom: 3px solid #216594;
      border-radius: 2px 2px 2px 2px;
      -webkit-border-radius: 2px 2px 2px 2px;
      -moz-border-radius: 2px 2px 2px 2px;

      .ant-row {
        margin: 0px !important;

        .dashboard-icon-center {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 58px;
        }

        p {
          margin-bottom: 0px;
          text-align: right;
          font-size: 12px;
          color: #595959;
          margin-top: 8px;
        }

        h2 {
          margin-bottom: 0px;
          text-align: left;
          font-size: 24px;
          color: #595959;
          font-weight: 500;
          margin-top: 0;
        }

        .ant-col {
          padding: 0px !important;
        }
      }
    }

    .dashboard-top-three {
      float: left;
      width: 100%;
      background: #ffffff;
      text-align: center;
      padding: 0em 1em;
      border-bottom: 3px solid #71518f;
      border-radius: 2px 2px 2px 2px;
      -webkit-border-radius: 2px 2px 2px 2px;
      -moz-border-radius: 2px 2px 2px 2px;

      .ant-row {
        margin: 0px !important;

        .dashboard-icon-center {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 58px;
        }

        p {
          margin-bottom: 0px;
          text-align: right;
          font-size: 12px;
          color: #595959;
          margin-top: 8px;
        }

        h2 {
          margin-bottom: 0px;
          text-align: right;
          font-size: 24px;
          color: #595959;
          font-weight: 500;
          margin-top: 0px;
        }

        .ant-col {
          padding: 0px !important;
        }
      }
    }

    .dashboard-top-four {
      float: left;
      width: 100%;
      background: #ffffff;
      text-align: center;
      padding: 0em 1em;
      border-bottom: 3px solid #71518f;
      border-radius: 2px 2px 2px 2px;
      -webkit-border-radius: 2px 2px 2px 2px;
      -moz-border-radius: 2px 2px 2px 2px;

      .ant-row {
        margin: 0px !important;

        .dashboard-icon-center {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 58px;
        }

        p {
          margin-bottom: 0px;
          text-align: right;
          font-size: 12px;
          color: #595959;
          margin-top: 8px;
        }

        h2 {
          margin-bottom: 0px;
          text-align: right;
          font-size: 24px;
          color: #595959;
          font-weight: 500;
          margin-top: 0px;
        }

        .ant-col {
          padding: 0px !important;
        }
      }
    }

    .dashboard-page-title {
      font-size: 20px;
      font-weight: 500;
      color: #000000;
    }

    .table-rows-select {
      float: left;
      width: 70px;
      margin-top: 1em;

      .ant-select-selector {
        height: 40px;
        width: 100%;
        border: 1px solid #d9d9d9;
        box-sizing: border-box;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;

        .ant-select-selection-item {
          padding-top: 5px;
        }
      }
    }

    .table-search-input {
      float: left;
      width: 100%;
      height: 40px;
      border: 1px solid #d9d9d9;
      margin-top: 1em;
      box-sizing: border-box;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
    }

    .tabletop-filter-options {
      text-decoration: underline;
      margin-top: 1em;
      font-size: 15px;
      line-height: 24px;
      float: right;
      text-align: center;
      width: 100%;
      margin-top: 20px;
    }
  }
}

// .forgot_passform{
//   .ant-form-item-label label{
//     overflow: inherit !important;
//     white-space: inherit !important;
//     text-align: right !important;
//     height: auto !important;
//   }
// }
#recovery_request {
  .ant-form-item-row {
    display: block;
  }

  .ant-form-item-label {
    overflow: inherit !important;
    height: auto !important;
    white-space: inherit !important;
    text-align: left;
    margin-bottom: 20px;

    label:before {
      position: relative;
      top: -11px;
    }

    label:after {
      content: " ";
    }

  }

  svg {
    fill: #1890ff;
  }
}

.dashboard-left-section {
  float: left;
  z-index: 1;
  position: relative;

  // padding-right: 30px;
  // .ant-layout-sider{
  //   width: 100% !important;
  //   min-width: 100% !important;
  //   max-width: 100% !important;
  // }
  .ant-layout-sider {
    background: #fff;

    // width: 100% !important;
    // min-width: 100% !important;
    // max-width: 100% !important;
    .ant-menu.ant-menu-dark {
      background: #fff;

      .ant-menu-dark {
        .ant-menu-sub {
          color: #f00;
        }
      }
    }
  }

  .ant-layout-sider-zero-width-trigger {
    background: #1890ff;
    top: 0px;
  }
}

.dashboard-right-section {
  // float: right;
  width: 100%;
  // height: 100vh;
  padding: 80px 30px;
  margin-left: 0%;

  @media screen and (min-width: "992px") {
    margin-left: 8%;

    &.dashboard-bailiff,
    &.dashboard-secretary {
      margin-left: 0;
    }
  }

  h2 {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    color: #000000;

    // margin: 2em 0px;
    span {
      margin-right: 5px;
    }
  }

  .table_outer_wrapper {
    float: left;
    width: 100%;
  }

  .form_outer_wrapper {
    float: left;
    width: 100%;
    background: #fff;
    padding: 1em 60px;
    margin-top: 20px;
  }

  .create_account {
    float: left;
    width: 100%;

    .ant-form-item {

      // float: left;
      // width: 50%;
      .ant-form-item-label {
        float: left;
        width: 40%;
      }

      .ant-select-selector {
        width: 100%;
      }

      .ant-upload-list-item-info {
        float: left;
        width: 100%;
        background: #e6f7ff;
      }
    }

    .ant-form-item-control-input-content {
      .ck.ck-editor {
        float: left;
        width: 380px;
      }

      .ck.ck-editor__editable_inline {
        border: 1px solid #d9d9d9;
        height: 150px;
      }
    }

    .register-btn-mask {
      label::after {
        content: " ";
      }

      .validate-btn {
        min-width: 90px;
        height: 40px;
        // margin-right: 10px;
        margin: 10px 5px;
        background: #40a9ff;
        border: 1px solid #40a9ff;
        font-size: 14px;
        color: #fff;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
      }

      .cancel-btn {
        min-width: 90px;
        height: 40px;
        // margin-right: 10px;
        margin: 0px 5px;
        border: 1px solid #d9d9d9;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
      }
    }

    .profile_uploadimg_wrapper {
      float: left;
      width: 100%;
      position: absolute;
      text-align: center;

      .uploadimage-bottom-select {
        float: right;
        width: 70%;
        text-align: left;

        .ant-form-item-row {
          width: 100%;
          display: grid;
        }

        label {
          text-align: left !important;
          width: 100%;
        }

        .ant-select {
          width: 100%;
        }
      }

      .groupe-button {
        .validate-btn {
          background: transparent;
          margin-left: auto !important;
          margin-right: auto !important;
          margin-top: 30px;
          color: #40a9ff;
          border: 1px solid #40a9ff;
          border-radius: 4px;
          -webkit-border-radius: 4px;
          -moz-border-radius: 4px;
        }
      }

      .uploadimg-label {
        float: right;
        width: 70%;
        text-align: left;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
      }
    }

    .input-currenct {
      float: left;
      width: 100%;

      input.ant-input {
        //width: 85%;
        margin-right: 8px;
      }

      .ant-form-item-control-input-content {
        display: flex;
        align-items: center;
      }

      svg {
        color: #40a9ff;
      }
    }
  }
}

.dashboard-secretary .form_outer_wrapper {
  padding: 0;
}

.ant-layout-sider {
  .ant-menu {
    .ant-menu-submenu {
      .ant-menu-sub {
        .ant-menu-item {
          a {
            color: #595959;
            font-size: 14px;
          }
        }
      }
    }
  }
}

.onlyscreen-template {
  float: left;
  width: 100%;

  .form_outer_wrapper {
    width: 80%;
    float: none;
    margin: 0 auto;
    background: none;
    padding: 0px;

    .onlyscreen_template_inner {
      float: left;
      width: 100%;
      background: #fff;
      padding: 32px 60px;
      margin-top: 20px;
    }
  }

  .form_tableouter_wrapper {
    width: 100%;
    float: none;
    margin: 0 auto;
    background: none;
    padding: 0px;

    .onlyscreen_template_inner {
      float: left;
      width: 100%;
      background: #fff;
      padding: 32px 0px;
      margin-top: 20px;
    }
  }

  .login_form_logo {
    float: left;
    width: 100%;
    text-align: center;
    margin: 0 0 1.5em 0;

    img {
      width: 85px;
    }
  }
}

.dashboard-right-section .create_account .profile_uploadimg_wrapper .uploadimage-bottom-select .ant-select {
  width: 100%;
  margin-top: -3px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 30px;
}

.dashboard-right-section {
  .pagewrapper_top {
    font-size: 20px;
    margin-top: 1.5em;
    width: 500;
    color: #000000;
    margin-bottom: 0px;
  }

  .form_outer_onlyscreenwrapper {
    width: 100%;
    float: left;
    background: #fff;
    margin-top: 1em;

    .form_onlyscreenwrapper {
      width: 100%;
      margin: 0 auto;

      .create_account {
        float: none;
        width: auto;
        margin: 0 auto;

        .ant-form-item {
          width: 100%;

          .ant-form-item-label {
            width: 30%;
          }
        }
      }

      .register-btn-mask {
        margin: 2em 2em 4em 0;
      }
    }

    // h2{
    //   margin-left: 12em;
    // }
  }
}

.ant-spin-nested-loading {
  .ant-spin-container {
    .ant-table {
      margin: 0px !important;
    }
  }
}

// .tabletoggle-section{
//   float: left;
//   width: 100%;
//   background: crimson;
// }

// .constatsencours-table{
//   .ant-table{
//     float: left;
//   width: 100%;
//   background: crimson;
//   .ant-table-cell{
//     float: left;
//     width: 50%;
//     background: darkgoldenrod;
//   }
//   }
// }

.tabletoggle-section2 {
  display: table-cell;
  height: 100%;
}

.ant-table-expanded-row {
  .ant-table {
    .ant-table-container {
      table {
        .ant-table-tbody {
          tr {
            td.ant-table-cell {
              // background: darkorange;
              padding: 5px;
              width: 50%;

              .tabletoggle-section {
                width: 100%;
                background: #ffffff;
                //border: 1px solid #E9E9E9;
                padding: 1.5em;
                border-radius: 2px;
                -webkit-border-radius: 2px;
                -moz-border-radius: 2px;

                .tabletoggle-section-title {
                  width: 100%;
                  padding-bottom: 1.1em;
                  border-bottom: 1px solid #e9e9e9;
                  justify-content: space-between;

                  .ant-space-item:first-child {
                    float: left;
                    width: 70%;

                    h2 {
                      margin: 0px;
                    }
                  }

                  .ant-space-item:last-child {
                    float: left;
                    // width: 30%;

                    a {
                      float: right;
                      border: 1px solid #40a9ff;
                      box-sizing: border-box;
                      border-radius: 4px;
                      font-size: 14px;
                      color: #40a9ff;
                      font-weight: normal;
                      padding: 3px 5px;

                      span {
                        margin-top: 5px;
                      }
                    }
                  }
                }

                .tabletoggle-section-list-mask {
                  float: left;
                  width: 100%;
                  // height: 300px;
                  // overflow-y: auto;
                  // overflow-x: hidden;
                  padding-right: 10px;

                  .tabletoggle-section-list {
                    float: left;
                    width: 100%;
                    margin: 1em 0;

                    .tabletoggle-section-info {
                      h2 {
                        font-size: 14px;
                        color: rgba(0, 0, 0, 0.65);
                        font-weight: 700;
                        line-height: 22px;
                        float: left;
                        width: 90%;
                        margin: 0px;
                      }

                      .table-audio-icons {
                        float: left;
                        width: 100%;

                        ul {
                          list-style: none;
                          float: right;
                          margin: 0px;
                          padding: 0px;

                          li {
                            float: left;
                            width: 50%;
                          }
                        }
                      }
                    }

                    .table-autio-date {
                      ul {
                        list-style: none;
                        float: left;
                        margin: 0px;
                        padding: 0px;
                        width: 100%;

                        li {
                          float: left;
                          font-size: 12px;
                          line-height: 22px;
                          color: rgba(0, 0, 0, 0.65);
                          margin-right: 50px;
                        }
                      }
                    }
                  }

                  .table-statu-fact {
                    float: left;
                    width: 100%;
                    margin-top: 2em;

                    h2 {
                      font-size: 18px;
                      line-height: 22px;
                      color: #595959;
                      margin: 0px 0px 1em 0px;
                      font-weight: 700;
                      padding-bottom: 1em;
                      border-bottom: 1px solid #e9e9e9;
                    }

                    .table-statu-fact-info {
                      .ant-col {
                        padding-bottom: 0px !important;

                        .ant-form-item {
                          margin-bottom: 0px;
                        }
                      }

                      .statut_facturation_wrapper {
                        .ant-col {
                          padding: 0px !important;
                        }

                        .table-info {
                          float: left;
                          width: 100%;
                          padding: 5px;
                          text-align: center;
                          border: 1px solid #d9d9d9;
                        }
                      }
                    }
                  }

                  .envoyer-btn {
                    // margin-top: 10px;
                    height: 32px;
                    background: #40a9ff;
                    border-radius: 4px;
                    -webkit-border-radius: 4px;
                    -moz-border-radius: 4px;
                    border: medium none;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 22px;
                    color: #ffffff;
                    padding: 0px 15px;
                    // margin-top: 65px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.facturation-label-remises {
  border: 0.5px solid lightgrey;
  padding: 4px;
  border-radius: 0px 3px 3px 0px;
}

.table-statu-fact-info-title {
  .nopadding {
    .info-title {
      padding: 9px 0px !important;
    }
  }
}

td.ant-table-cell {
  white-space: pre !important;
}

.dashboard-right-section {
  .topfour-coloum {
    margin-top: 1em;
    width: inherit;

    @media screen and (max-width: "766px") {
      margin-left: 15%;
    }

    .dashboard-top-one {
      float: left;
      width: 100%;
      height: 100%;
      background: #ffffff;
      text-align: center;
      padding: 0em 1em;
      border-bottom: 3px solid #a96f9f;
      border-radius: 2px 2px 2px 2px;
      -webkit-border-radius: 2px 2px 2px 2px;
      -moz-border-radius: 2px 2px 2px 2px;

      .ant-row {
        margin: 0px !important;

        .dashboard-icon-center {
          display: flex;
          align-items: center;
          justify-content: left;
          height: 58px;
        }

        p {
          margin-bottom: 0px;
          text-align: right;
          font-size: 12px;
          color: #595959;
          margin-top: 8px;
        }

        h2 {
          margin-bottom: 0px;
          text-align: right;
          font-size: 24px;
          color: #595959;
          font-weight: 500;
          margin-top: 0px;
        }

        .ant-col {
          padding: 0px !important;
        }
      }
    }

    .dashboard-top-two {
      float: left;
      width: 100%;
      height: 100%;
      background: #ffffff;
      text-align: center;
      padding: 0em 1em;
      border-bottom: 3px solid #019bcd;
      border-radius: 2px 2px 2px 2px;
      -webkit-border-radius: 2px 2px 2px 2px;
      -moz-border-radius: 2px 2px 2px 2px;

      .ant-row {
        margin: 0px !important;

        .dashboard-icon-center {
          display: flex;
          align-items: center;
          justify-content: left;
          height: 58px;
        }

        p {
          margin-bottom: 0px;
          text-align: right;
          font-size: 12px;
          color: #595959;
          margin-top: 8px;
        }

        h2 {
          margin-bottom: 0px;
          text-align: right;
          font-size: 24px;
          color: #595959;
          font-weight: 500;
          margin-top: 0px;
        }

        .ant-col {
          padding: 0px !important;
        }
      }
    }

    .dashboard-top-five {
      float: left;
      width: 100%;
      background: #ffffff;
      text-align: center;
      padding: 0em 1em;
      border-bottom: 3px solid #216594;
      border-radius: 2px 2px 2px 2px;
      -webkit-border-radius: 2px 2px 2px 2px;
      -moz-border-radius: 2px 2px 2px 2px;

      .ant-row {
        margin: 0px !important;

        .dashboard-icon-center {
          display: flex;
          align-items: center;
          justify-content: left;
          height: 58px;
        }

        p {
          margin-bottom: 0px;
          text-align: right;
          font-size: 12px;
          color: #595959;
          margin-top: 8px;
        }

        h2 {
          margin-bottom: 0px;
          text-align: right;
          font-size: 24px;
          color: #595959;
          font-weight: 500;
          margin-top: 0px;
        }

        .ant-col {
          padding: 0px !important;
        }
      }
    }

    .dashboard-top-three {
      float: left;
      width: 100%;
      height: 100%;
      background: #ffffff;
      text-align: center;
      padding: 0em 1em;
      border-bottom: 3px solid #71518f;
      border-radius: 2px 2px 2px 2px;
      -webkit-border-radius: 2px 2px 2px 2px;
      -moz-border-radius: 2px 2px 2px 2px;

      .ant-row {
        margin: 0px !important;

        .dashboard-icon-center {
          display: flex;
          align-items: center;
          justify-content: left;
          height: 58px;
        }

        p {
          margin-bottom: 0px;
          text-align: right;
          font-size: 12px;
          color: #595959;
          margin-top: 8px;
        }

        h2 {
          margin-bottom: 0px;
          text-align: right;
          font-size: 24px;
          color: #595959;
          font-weight: 500;
          margin-top: 0px;
        }

        .ant-col {
          padding: 0px !important;
        }
      }
    }

    .dashboard-top-four {
      float: left;
      width: 100%;
      background: #ffffff;
      text-align: center;
      padding: 0em 1em;
      border-bottom: 3px solid #71518f;
      border-radius: 2px 2px 2px 2px;
      -webkit-border-radius: 2px 2px 2px 2px;
      -moz-border-radius: 2px 2px 2px 2px;

      .ant-row {
        margin: 0px !important;

        .dashboard-icon-center {
          display: flex;
          align-items: center;
          justify-content: left;
          height: 58px;
        }

        p {
          margin-bottom: 0px;
          text-align: right;
          font-size: 12px;
          color: #595959;
          margin-top: 8px;
        }

        h2 {
          margin-bottom: 0px;
          text-align: right;
          font-size: 24px;
          color: #595959;
          font-weight: 500;
          margin-top: 0px;
        }

        .ant-col {
          padding: 0px !important;
        }
      }
    }
  }

  @media screen and (max-width: "766px") {
    &.dashboard-bailiff .topfour-coloum {
      margin-left: 0;
    }
  }

  @media screen and (max-width: "766px") {
    &.dashboard-bailiff .topfour-coloum {
      margin-left: 0;
    }
  }

  .infoalready-exesting {
    label {
      color: #40a9ff;
    }

    input[type="text"] {
      border: 1px solid #40a9ff;
    }
  }
}

.constatsencours-table {
  .table-dropdown {
    border: 1px solid #d9d9d9;
    padding: 7px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
  }

  .ant-table-content {
    table {
      .ant-table-thead {
        tr {
          th:first-child {
            text-align: left;
            width: 0%;
          }

          th:nth-child(2) {
            text-align: left;
            width: 2%;
          }

          th:nth-child(3) {
            text-align: left;
            width: 15%;
          }

          th:nth-child(4) {
            text-align: left;
            width: 15%;
          }

          th:nth-child(5) {
            text-align: left;
            width: 23%;
          }

          th:nth-child(6) {
            text-align: left;
            width: 15%;
          }

          th:nth-child(7) {
            text-align: left;
            width: 15%;
          }

          th:nth-child(8) {
            text-align: left;
            width: 5%;
          }

          th:last-child {
            text-align: left;
            width: 10%;
          }
        }
      }

      .ant-table-tbody {
        tr {
          td:first-child {
            text-align: left;
            width: 0%;
          }

          td:nth-child(2) {
            text-align: left;
            width: 2%;
          }

          td:nth-child(3) {
            text-align: left;
            width: 15%;
          }

          td:nth-child(4) {
            text-align: left;
            width: 15%;
          }

          td:nth-child(5) {
            text-align: left;
            width: 23%;
          }

          td:nth-child(6) {
            text-align: left;
            width: 15%;
          }

          td:nth-child(7) {
            text-align: left;
            width: 15%;
          }

          td:nth-child(8) {
            text-align: left;
            width: 5%;
          }

          td:last-child {
            text-align: left;
            width: 10%;
          }
        }
      }
    }
  }

  .ant-form-item-has-success {
    .ant-input {
      text-align: center;
    }
  }
}

.tabletop-filter-options {
  float: right;
  width: 100%;
  text-align: center;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  // margin-top: 10px;
  text-decoration: underline;
}

.tableselect_toprow {
  .table-rows-select {
    width: 90%;
  }
}

.tableselect_toprow .ant-row {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1em;
  text-align: end;

  .ant-select {
    text-align-last: left;
  }
}

#col1,
#col2,
#col3 {
  border: 2px solid red;
  display: none;
}

.elements-retournes-wrapper {
  float: left;
  width: 100%;

  .ant-col {
    padding: 0px !important;

    .elements-retournes-text {
      border: 1px solid #d9d9d9;
      padding: 5px 10px;
      text-align: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.65);
    }
  }
}

.tabletoggle-section-list-mask .table-statu-fact .elements-retournes-wrapper .totalnofoimagephotos .imagephotos-form .imagephotos-row .elements-retournes-text {
  font-size: 13px;
}

.creerunconstat-wrapper {
  float: left;
  width: 100%;

  h2.pagewrapper_top {
    margin: 0.5em 0 0.5em 0;
  }

  .input-bottom-text {
    font-weight: normal;
    font-size: 13px;
    font-style: italic;
    line-height: 22px;
    margin-top: 1em;
    float: left;
    color: rgba(0, 0, 0, 0.3);
  }

  .nameoffinding {
    margin: 1em 0 0 0;
  }

  .form_outer_onlyscreenwrapper {
    padding: 2em 0;

    .toptitlewithunderline {
      width: 70%;
      float: none;
      margin: 0 auto;
      margin-bottom: 1.5em;
      border-bottom: 2.5px solid #e9e9e9;
    }

    .form_onlyscreenwrapper {
      width: 35%;
      margin: 0 auto;

      .create_account {
        float: none;
        width: auto;
        margin: 0 auto;

        .ant-form-item {
          .ant-form-item-label {
            width: 100% !important;
            text-align: left;
            font-weight: bold;
            font-size: 14px;
            color: #595959;
          }
        }
      }

      .register-btn-mask {
        margin: 2em 2em 4em 0;
      }

      .register-btn-mask {
        text-align: center;
      }
    }
  }
}

// .creer-constat-wrapper{
//   .form_outer_wrapper{
//     float: left;
//     width: 100%;
//     background: transparent;
//   }
//   .onlyscreen_template_inner {
//     float: left;
//     width: 100%;
//     background: #fff;
//     .dashboard-right-section{
//       float: left;
//       width: 100%;
//       margin: 0px;
//       .onlyscreen_template_wrapper{
//         margin: 0 auto;
//         width: 35%;
//         .form_onlyscreenwrapper{
//           width: 100%;
//         }
//       }
//     }

//   }
// }

.creer-constat-wrapper {
  .dashboard-right-section {
    float: left;
    width: 100%;
    margin: 0px;

    .form_outer_wrapper {
      background: transparent;
      float: none;
      width: 80%;
      margin: 0 auto;
      height: 100vh;
      color: #595959;

      .ant-form-item-label {
        float: none !important;
        font-weight: bold;
        font-size: 14px;
        line-height: 22px;
        text-align: left;
        width: 40% !important;
        // text-transform: uppercase;
      }

      .input-bottom-text {
        font-weight: normal;
        font-size: 13px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.3);
      }

      .register-btn-mask {
        text-align: center;
      }

      .form_input_field {
        float: left;
        width: 100%;
        height: 100px;
        resize: none;
      }
    }

    .nouvelle-btn {
      float: left;
      min-width: 210px;
      height: 39px;
      background: #ffffff;
      border: 1px solid #40a9ff;
      box-sizing: border-box;
      color: #40a9ff;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
    }
  }
}

.telephone_no {
  float: left;
  width: 100%;
}

.form_password {
  float: left;
  width: 100%;
}

.elemntsRetournes-table {
  width: 33%;
}

.date-table-mesConstats {
  width: 16%;
}

.nomDoConstats-table {
  width: 45%;
}

.pageConstats-table p {
  text-align: center;
}

.statutConstats-table p {
  text-align: center;
}

.mesconstats-table th {
  text-align: center;
}

.spaceCommentarie-table {
  text-align: center;
  display: flex;
  align-content: center;
  align-items: center;
  width: 100%;
}

.mesconstats-table {
  .telecharger-link {
    height: 22px;
    border: 1px solid #40a9ff;
    color: #40a9ff !important;
    padding: 5px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
  }

  .ant-table-tbody {
    tr {
      // td:nth-child(2) {
      //   min-width: 175px;
      // }

      // td:nth-child(3) {
      //   min-width: 100px;
      // }

      // td:nth-child(4) {
      //   min-width: 160px;
      // }

      // td:nth-child(5) {
      //   width: 160px;
      // }

      td:nth-child(6) {
        //width: 65px;
        text-align: center;

        span {
          svg {
            margin: 0px 10px;
          }
        }
      }

      td:last-child {
        justify-content: center;
        display: table-cell;
        padding-left: 35px;

        span.anticon.anticon-mail {
          float: none;
          display: inline-block;
          margin: 0px 15px;
        }
      }
    }

    tr {
      td {
        span {
          svg {
            // font-size: 18px;
          }
        }

        a {
          color: rgba(0, 0, 0, 0.85);
          text-decoration: none;

          &:hover {
            color: #40a9ff;
          }
        }
      }
    }

    .tabletoggle-section {
      .tabletoggle-section-title {
        float: left;
        width: 100% !important;

        .ant-space-item {
          float: left;
          width: 100% !important;

          h2 {
            text-align: left;
          }
        }
      }

      .tabletoggle-section-list-mask {
        float: left;
        width: 100%;

        .tabletoggle-section-list {
          .tabletoggle-section-info {
            h2 {
              text-align: left;
            }
          }
        }

        .table-statu-fact {
          h2 {
            text-align: left;
          }
        }
      }
    }
  }
}

.header-profile-dropdown {
  .profile_notification {
    margin-right: 15px;

    .profile_user {
      margin-left: 10px;

      .profile-dropdown {
        span.anticon-down {
          float: left;
          margin-left: 10px;
        }
      }
    }
  }
}

.customnotofication {
  margin-top: 2em;
  padding: 0px;
  overflow: visible;

  .ant-notification-notice-message {
    display: none;
  }

  .ant-notification-notice-close {
    display: block;
    position: absolute;
    right: -5px;
    top: -5px;

    .ant-notification-close-icon {
      svg {
        background: #1890ff;
        color: #ffffff;
        font-weight: bold;
        border: 1px solid #146fc5;
        padding: 4px;
        border-radius: 50%;
        height: 21px;
        width: 21px;
      }
    }
  }

  .ant-tabs-nav-list {
    width: 100%;

    .ant-tabs-tab {
      margin-right: auto !important;
      margin-left: auto !important;
      text-align: center;

      .ant-tabs-tab-btn {
        width: 100%;
      }
    }
  }

  .ant-tabs-content-holder {
    height: 70vh;
    overflow-x: hidden;
    overflow-y: scroll;
    margin: 0px 5px 10px 0;

    .ant-tabs-tabpane {
      .news-wrapper {
        float: left;
        width: 100%;

        .newsinformation {
          float: left;
          width: 100%;
          padding: 0 1em 1em;
          border-bottom: 2px solid #dedede;
          margin-bottom: 15px;

          .statusicon {
            float: left;
            width: 10%;
            text-align: left;
            top: 4px;
            position: relative;

            .stinactive {
              color: #595959;
            }

            .stactive {
              color: #d4380d;
            }
          }

          .statustext {
            float: right;
            width: 90%;

            h2 {
              float: left;
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-weight: 500;
              font-size: 16px;
              line-height: 28px;
              color: #595959;
            }

            p {
              font-weight: normal;
              font-size: 14px;
              line-height: 21px;
              color: #595959;
              margin-bottom: 0px;
            }
          }

          .ant-radio-inner {
            border-color: #1890ff;
          }
        }

        .ant-row.ant-form-item {
          float: left;
          width: 100%;
          margin: 0em 0 1em 0;
        }
      }

      .button-news {
        position: sticky;
        bottom: 0;
        float: left;
        width: 100%;

        .notificationbtn {
          width: 92%;

          background: #1890ff;
          color: #ffffff;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          border-radius: 4px;
          -webkit-border-radius: 4px;
          -moz-border-radius: 4px;

          svg {
            margin-right: 0px;
          }
        }

        .ant-form-item {
          margin-bottom: 0px;
        }
      }
    }
  }

  .ant-tabs-content-holder::-webkit-scrollbar {
    width: 5px;
  }

  .ant-tabs-content-holder::-webkit-scrollbar-thumb {
    /* background: linear-gradient(45deg, #06dee1, #79ff6c); 
    background: linear-gradient(transparent, #40A9FF);*/
    background: #40a9ff;
    border-radius: 3px;
    height: 60px;
  }

  .ant-tabs-content-holder::-webkit-scrollbar-track {
    border: 5px solid #dedede !important;
    box-shadow: inset 0 0 2.5px 2px rgba(0, 0, 0, 0.5);
  }
}

.ant-dropdown-trigger>.anticon.anticon-down,
.ant-dropdown-link>.anticon.anticon-down,
.ant-dropdown-button>.anticon.anticon-down {
  vertical-align: baseline;
  display: inline-block;
  font-size: 10px;
  margin-left: 5px;
}

.noleftmenu_section {
  .topfive-coloum {
    //margin-top: 5em;

    .dashboard-top-one {
      float: left;
      width: 100%;
      background: #ffffff;
      text-align: center;
      padding: 0em 1em;
      border-bottom: 3px solid #a96f9f;
      border-radius: 2px 2px 2px 2px;
      -webkit-border-radius: 2px 2px 2px 2px;
      -moz-border-radius: 2px 2px 2px 2px;

      .ant-row {
        margin: 0px !important;

        .dashboard-icon-center {
          display: flex;
          align-items: center;
          justify-content: left;
          height: 58px;
        }

        p {
          margin-bottom: 0px;
          text-align: right;
          font-size: 12px;
          color: #595959;
          margin-top: 8px;
        }

        h2 {
          margin-bottom: 0px;
          text-align: right;
          font-size: 24px;
          color: #595959;
          font-weight: 500;
          margin-top: -3px;
        }

        .ant-col {
          padding: 0px !important;
        }
      }
    }

    .dashboard-top-two {
      float: left;
      width: 100%;
      background: #ffffff;
      text-align: center;
      padding: 0em 1em;
      border-bottom: 3px solid #019bcd;
      border-radius: 2px 2px 2px 2px;
      -webkit-border-radius: 2px 2px 2px 2px;
      -moz-border-radius: 2px 2px 2px 2px;

      .ant-row {
        margin: 0px !important;

        .dashboard-icon-center {
          display: flex;
          align-items: center;
          justify-content: left;
          height: 58px;
        }

        p {
          margin-bottom: 0px;
          text-align: right;
          font-size: 12px;
          color: #595959;
          margin-top: 8px;
        }

        h2 {
          margin-bottom: 0px;
          text-align: right;
          font-size: 24px;
          color: #595959;
          font-weight: 500;
          margin-top: -3px;
        }

        .ant-col {
          padding: 0px !important;
        }
      }
    }

    .dashboard-top-five {
      float: left;
      width: 100%;
      background: #ffffff;
      text-align: center;
      padding: 0em 1em;
      border-bottom: 3px solid #216594;
      border-radius: 2px 2px 2px 2px;
      -webkit-border-radius: 2px 2px 2px 2px;
      -moz-border-radius: 2px 2px 2px 2px;

      .ant-row {
        margin: 0px !important;

        .dashboard-icon-center {
          display: flex;
          align-items: center;
          justify-content: left;
          height: 58px;
        }

        p {
          margin-bottom: 0px;
          text-align: right;
          font-size: 12px;
          color: #595959;
          margin-top: 8px;
        }

        h2 {
          margin-bottom: 0px;
          text-align: right;
          font-size: 24px;
          color: #595959;
          font-weight: 500;
          margin-top: 0px;
        }

        .ant-col {
          padding: 0px !important;
        }
      }
    }

    .dashboard-top-three {
      float: left;
      width: 100%;
      background: #ffffff;
      text-align: center;
      padding: 0em 1em;
      border-bottom: 3px solid #71518f;
      border-radius: 2px 2px 2px 2px;
      -webkit-border-radius: 2px 2px 2px 2px;
      -moz-border-radius: 2px 2px 2px 2px;

      .ant-row {
        margin: 0px !important;

        .dashboard-icon-center {
          display: flex;
          align-items: center;
          justify-content: left;
          height: 58px;
        }

        p {
          margin-bottom: 0px;
          text-align: right;
          font-size: 12px;
          color: #595959;
          margin-top: 8px;
        }

        h2 {
          margin-bottom: 0px;
          text-align: right;
          font-size: 24px;
          color: #595959;
          font-weight: 500;
          margin-top: -3px;
        }

        .ant-col {
          padding: 0px !important;
        }
      }
    }

    .dashboard-top-four {
      float: left;
      width: 100%;
      background: #ffffff;
      text-align: center;
      padding: 0em 1em;
      border-bottom: 3px solid #71518f;
      border-radius: 2px 2px 2px 2px;
      -webkit-border-radius: 2px 2px 2px 2px;
      -moz-border-radius: 2px 2px 2px 2px;

      .ant-row {
        margin: 0px !important;

        .dashboard-icon-center {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 58px;
        }

        p {
          margin-bottom: 0px;
          text-align: right;
          font-size: 12px;
          color: #595959;
          margin-top: 8px;
        }

        h2 {
          margin-bottom: 0px;
          text-align: right;
          font-size: 24px;
          color: #595959;
          font-weight: 500;
          margin-top: 0px;
        }

        .ant-col {
          padding: 0px !important;
        }
      }
    }
  }
}

.ant-upload.ant-upload-select-picture-card {
  background: none;
  border: medium none;
  margin: 0px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  width: 100%;
  height: 100%;

  img {
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    min-height: 100px;
    object-fit: cover;
  }

  .uploadimg-wrapper {
    position: relative;
    bottom: 2em;

    .uploadimg-icons {
      position: absolute;
      right: -25px;
      width: 100%;
      bottom: 0px;

      img {
        width: auto;
        height: auto;
        min-height: auto;
        border-radius: inherit;
        object-fit: none;
      }
    }
  }
}

.recoverypassword {
  float: left;
  width: 100%;
  background: #ffffff;

  .recovery-password {
    margin: 0 auto;
    width: 80%;
  }
}

#components-layout-demo-fixed .logo {
  width: 120px;
  height: 31px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px 24px 16px 0;
  float: left;
}

.site-layout .site-layout-background {
  background: #fff;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #e6f7ff !important;
}

.only-for-web {
  display: block;
}

.only-for-mobile {
  display: none;
}

.ant-table-content {
  table {
    .ant-table-tbody {
      tr {
        td:nth-child(6) {
          .anticon svg {
            //fill: #1890ff;
            // width: 20px;
            //height: 20px;
          }
        }

        td:last-child {
          .ant-space-item:first-child {
            .anticon svg {
              //fill: #1890ff;
              //width: 20px;
              //height: 20px;
            }
          }

          .ant-space-item:last-child {
            .anticon svg {
              //fill: #1890ff;
              //width: 20px;
              //height: 20px;
            }
          }
        }
      }
    }
  }
}

.ajouter-un-fichier-btn {
  svg {
    width: 15px !important;
    height: 15px !important;
  }
}

.cpvillage-two {
  margin-bottom: 0px;
}

// .menulink.ant-menu-item-selected{
// span{
//  color: #333 !important;
// }
// }
.ant-menu-dark .ant-menu-item-selected .anticon,
.ant-menu-dark .ant-menu-item-selected span a {
  color: #333 !important;
}

.dashboard_logo {
  svg {
    max-width: 65px;
  }
}

.statistiques_wrapper {
  float: left;
  width: 100%;
  padding: 1em;
  background: #fff;
}

.chart-top-select-wrapper {
  h2 {
    margin-bottom: 1em;
  }

  .chart-top-select {
    margin-bottom: 4em;

    .ant-select {
      float: left;
      width: 90%;

      .ant-select-selector {
        float: left;
        width: 100%;
      }
    }
  }
}

.ant-badge-status-dot {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 10px;
  height: 10px;
  vertical-align: middle;
  border-radius: 50%;
}

.ant-menu-item a {
  color: rgba(0, 0, 0, 0.85);
}

.ant-form-item-has-error .ant-form-item-explain,
.ant-form-item-has-error .ant-form-item-split {
  color: #ff4d4f;
  font-size: 12px;
  margin-bottom: 0.5em;
}

.top-search-section {
  float: right;
}

// .mesconstats-table {
//   .ant-table-content {
//     table {
//       .ant-table-tbody {
//         tr {
//           td:nth-child(6) {
//             text-align: center;
//           }

//           td:nth-child(7) {
//             text-align: center;
//           }

//           td:nth-child(8) {
//             text-align: center;
//           }

//           td:nth-child(9) {
//             text-align: center;
//           }

//           td:last-child {
//             text-align: center;
//           }
//         }
//       }
//     }
//   }
// }

// .mesconstats-table {
//   .ant-table-content {
//     table {
//       .ant-table-thead {
//         tr {
//           th:first-child {
//             text-align: left;
//             width: 0;
//           }

//           th:nth-child(2) {
//             text-align: left;
//             width: 20%;
//           }

//           th:nth-child(3) {
//             text-align: left;
//             width: 20%;
//           }

//           th:nth-child(4) {
//             text-align: left;
//             width: 20%;
//           }

//           th:nth-child(5) {
//             text-align: center;
//             width: 15%;
//           }

//           th:nth-child(6) {
//             text-align: center;
//             width: 5%;
//           }

//           th:nth-child(7) {
//             text-align: center;
//             width: 5%;
//           }

//           th:nth-child(8) {
//             text-align: center;
//             width: 5%;
//           }

//           th:nth-child(9) {
//             text-align: center;
//             width: 5%;
//           }

//           th:last-child {
//             text-align: center;
//             width: 5%;
//           }
//         }
//       }

//       .ant-table-tbody {
//         tr {
//           td:first-child {
//             text-align: left;
//             width: 0;
//           }

//           td:nth-child(2) {
//             text-align: left;
//             width: 20%;
//           }

//           td:nth-child(3) {
//             text-align: left;
//             width: 20%;
//           }

//           td:nth-child(4) {
//             text-align: left;
//             width: 20%;
//           }

//           td:nth-child(5) {
//             text-align: center;
//             width: 15%;
//           }

//           td:nth-child(6) {
//             text-align: center;
//             width: 5%;
//           }

//           td:nth-child(7) {
//             text-align: center;
//             width: 5%;
//           }

//           td:nth-child(8) {
//             text-align: center;
//             width: 5%;
//           }

//           td:nth-child(9) {
//             text-align: center;
//             width: 5%;
//           }

//           td:last-child {
//             text-align: center;
//             width: 5%;
//           }
//         }
//       }
//     }
//   }
// }

.tabletop_addbtn {
  float: left;
  min-width: 215px;
  height: 39px;
  background: #ffffff;
  padding: 10px;
  border: 1px solid #00a0e9;
  border-radius: 0px;
  color: #00a0e9;
  font-size: 10px;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
}

// .ant-table-row-expand-icon.ant-table-row-expand-icon-collapsed{
//   display: none;
// }
.ant-table-tbody>tr>td>.ant-table-wrapper:only-child .ant-table-tbody>tr:last-child>td:first-child,
.ant-table-tbody>tr>td>.ant-table-wrapper:only-child .ant-table-tbody>tr:last-child>td:last-child {
  border-radius: 0;
  float: unset;
  vertical-align: top;
  border: 1px solid #e9e9e9;

  h2 {
    min-width: 300px;
  }
}

.secretarydashboard-table {
  .ant-table-content {
    table {
      .ant-table-tbody {
        tr {
          td:first-child {
            text-align: center;
          }

          td:nth-child(2) {
            width: 8%;
            text-align: center;
          }

          td:nth-child(3) {
            text-align: left;
            width: 10%;
          }

          td:nth-child(4) {
            text-align: left;
            width: 12%;
          }

          td:nth-child(5) {
            text-align: left;
            width: 54%;
          }

          td:nth-child(6) {
            text-align: left;
            width: 12%;
          }

          td:last-child {
            width: 4%;
            text-align: center;
          }
        }
      }
    }
  }
}

.mybillsbailiff-table {
  .ant-table-content {
    table {
      .ant-table-thead {
        tr {
          th:first-child {
            text-align: center;
          }

          th:nth-child(2) {
            text-align: center;
          }

          th:nth-child(3) {
            text-align: center;
          }

          th:nth-child(4) {
            text-align: center;
          }

          th:nth-child(5) {
            text-align: center;
          }

          th:nth-child(6) {
            text-align: center;
          }

          th:last-child {
            text-align: center;
          }
        }
      }

      .ant-table-tbody {
        tr {
          td:first-child {
            text-align: center;
            width: 16.6%;
          }

          td:nth-child(2) {
            width: 16.6%;
            text-align: center;
          }

          td:nth-child(3) {
            text-align: center;
            width: 16.6%;
          }

          td:nth-child(4) {
            text-align: center;
            width: 16.6%;
          }

          td:nth-child(5) {
            text-align: center;
            width: 16.6%;
          }

          td:last-child {
            width: 16.6%;
            text-align: center;
          }
        }
      }
    }
  }
}

.userprofile-layout-wrapper {
  float: left;
  width: 100%;
  position: relative;

  .ant-layout.ant-layout-has-sider {
    margin-top: 15px;

    .ant-layout-sider {
      background: #ffffff;

      .ant-layout-sider-children {
        .ant-menu-inline {
          border: medium none;
        }
      }
    }

    .ant-layout {
      background: #ffffff;

      .ant-layout-header {
        box-shadow: unset;

        h2 {
          padding: 10px;
          margin: 0px;
          font-weight: 500;
          font-size: 20px;
          line-height: 23px;
          color: rgba(0, 0, 0, 0.85);
        }
      }

      .ant-layout-content {
        float: left;
        width: 100%;
        padding: 2em;

        .content-section-wrapper {
          .bailiffprofile-form {
            .profile_uploadimg_wrapper {
              .ant-upload-picture-card-wrapper {
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
}

.dashboard-right-section {
  .form_outer_onlyscreenwrapper {
    .form_onlyscreenwrapper {
      .creerungroupe_form {
        float: none;
        width: 30%;
        margin: 0 auto;

        .ant-form-item {
          .ant-form-item-label {
            width: 30% !important;
            text-align: left;
            font-weight: bold;
            font-size: 14px;
            color: #595959;

            label {
              float: right;
            }
          }
        }
      }
    }
  }
}

.ant-menu-item {
  span {
    a {
      color: #000 !important;
    }
  }
}

.creerunconstat_title {
  width: 75%;
  border-bottom: 2px solid #e9e9e9;
  padding-bottom: 0.3em;
  margin: 0 auto;
  margin-top: 2em;
  color: #595959;
  margin-bottom: 1em;
  // margin-left: 0px;
}

.creerunconstat_title_nounderline {
  width: 75%;
  padding-bottom: 0.3em;
  margin: 0 auto;
  margin-top: 2em;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 1em;
}

.createbailiff_report_wrapper {
  width: 40% !important;
  float: none !important;
  margin: 0 auto !important;
}

// .ant-form-item-label > label{
//   text-transform: capitalize;
// }

.ant-upload.ant-upload-drag p.ant-upload-hint {
  color: rgba(0, 0, 0, 0.2);
  font-weight: normal;
  font-size: 13px;
  line-height: 22px;
}

.innerpages_title {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 20px;
  margin: 2em 0;
  line-height: 28px;
}

.creerungroupe_form {
  label {
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.65);
  }

  .register-btn-mask {
    .creerungroupe_btn_gropup {
      text-align: center;
      margin-top: 2em;
    }

    .validate-btn {
      min-width: 90px;
      height: 40px;
      margin-right: 10px;
      background: #40a9ff;
      border: 1px solid #40a9ff;
      font-size: 14px;
      color: #fff;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
    }

    .cancel-btn {
      min-width: 90px;
      height: 40px;
      margin-right: 10px;
      border: 1px solid #d9d9d9;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
    }
  }

  .ant-form-item {
    margin-bottom: 0px;
  }
}

// .create_account {
//   .ant-form-item {
//     margin-bottom: 0px;
//   }
// }

.bailiffaccount_form,
.adminaccount_form,
.create_news {
  .ant-form-item {
    margin-bottom: 0px;
  }

  .infoalready-exesting {
    .ant-form-item-control-input {
      margin-bottom: 3px;
    }
  }
}

.ckeditor-wrapper {
  float: right;
  width: 100%;
}

.ckeditor_label_wrapper {
  float: left;
  width: 100%;

  .ant-form-item-label {
    float: right;
    width: 40%;
  }

  .ant-form-item-control {
    float: right;
    width: 40%;
  }
}

.leftmenutop_title {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  color: #000000;
  margin-bottom: 2em;
}

.profile_bailiff_wrapper {
  .bailiffprofile-form {
    label {
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.85);
    }
  }
}

.inputfield-small {
  width: 50%;
}

.inputfield-middle {
  width: 80% !important;
}

.inputfield-full {
  width: 100%;
  margin-top: 3px;
}

.profile_bailiff_wrapper {
  .validate-btn {
    min-width: 90px;
    height: 40px;
    margin-right: 10px;
    background: #40a9ff;
    border: 1px solid #40a9ff;
    font-size: 14px;
    color: #fff;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
  }

  .cancel-btn {
    min-width: 90px;
    height: 40px;
    margin-right: 10px;
    border: 1px solid #d9d9d9;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
  }

  .ant-upload-select-picture-card {
    float: left;
  }

  .profile_uploadimg_wrapper {
    h2 {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.85);
    }
  }

  .bailiffprofile-address {
    position: relative;
    top: 28px;
  }
}

.topalertonsuccess {
  float: left;
  width: 400px;
  position: absolute;
  right: -100px;
  background: #f6ffed;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #595959;
  padding: 10px 15px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border: 1px solid #b7eb8f;

  svg {
    margin-right: 10px;
  }

  .close-alert {
    svg {
      fill: #595959;
    }
  }
}

.calendra-btn {
  margin-top: 2em;
}

.commonpagetitle_leftmenu {
  float: left;
  width: 100%;
  margin-top: 1em;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: rgba(0, 0, 0, 0.85);
}

.dashboard-right-section h2 {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.85);
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.creerungroupe_form {
  width: 50% !important;
  margin: 0 auto;
  float: none !important;

  .creerungroupe_form_btn {
    width: 60%;
    float: right;
    text-align: left;
  }
}

.bailiffdashboard {
  float: none !important;
  margin: 0 auto !important;
  width: 85% !important;
  background: none !important;

  .nouvelle-btn {
    background-color: #ffffff;
    border: 1px solid #1890ff;
    color: #1890ff !important;
    height: 39px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
  }

  .topthreetitle {
    margin-top: 1.1em !important;
    font-weight: 500;
    font-size: 24px;
    color: rgba(0, 0, 0, 0.65);

    a {
      margin-top: 1.1em !important;
      font-weight: 500;
      font-size: 24px;
      color: rgba(0, 0, 0, 0.65);
    }
  }
}

.secretarydashboard {
  float: none !important;
  margin: 0 auto !important;
  background: none !important;

  .nouvelle-btn {
    background-color: transparent;
    border: 1px solid #1890ff;
    color: #1890ff !important;
    height: 39px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
  }
}

.viewnews_table {
  .ant-table-content {
    table {
      .ant-table-thead {
        tr {
          th:first-child {
            text-align: center;
          }

          th:nth-child(2) {
            text-align: center;
          }

          th:nth-child(3) {
            text-align: left;
          }

          th:nth-child(4) {
            text-align: left;
          }

          th:last-child {
            text-align: center;
          }
        }
      }

      .ant-table-tbody {
        tr {
          td:first-child {
            text-align: center;
          }

          td:nth-child(2) {
            width: 20%;
            text-align: center;
          }

          td:nth-child(3) {
            text-align: left;
            width: 20%;
          }

          td:nth-child(4) {
            text-align: left;
            width: 33%;
          }

          td:last-child {
            width: 27%;
            text-align: center;
          }
        }
      }
    }
  }
}

.registration_wrapper {
  float: left;
  width: 100%;
  background: #f5f5f5;

  .onlyscreen_template_inner {
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;

    h2 {
      float: none;
      margin: 0 auto;
      width: 65%;
      margin-bottom: 2em;
    }

    .create_account {
      .ant-form-item {
        margin-bottom: 3px;

        .ant-form-item.cpvillage-two {
          margin-bottom: 0px;
        }

        .ant-form-item-explain {
          margin-bottom: 0px;
        }
      }

      .register-btn-mask {
        margin: 0em !important;
      }
    }
  }
}

.voirlesgroupes_table {
  .ant-table-content {
    table {
      .ant-table-thead {
        tr {
          th:first-child {
            text-align: center;
            width: 50%;
          }

          th:nth-child(2) {
            text-align: center;
            width: 25%;
          }

          /*th:nth-child(3) {
            text-align: center;
            width: 30%;
          }*/

          th:last-child {
            text-align: center;
            width: 25%;
          }
        }
      }

      .ant-table-tbody {
        tr {
          td:first-child {
            text-align: center;
            width: 30%;
          }

          td:nth-child(2) {
            text-align: center;
            width: 40%;
          }

          td:nth-child(3) {
            text-align: center;
            width: 30%;
          }

          td:last-child {
            text-align: center;
            width: 30%;
          }
        }

        .ant-table-expanded-row:hover td,
        .ant-table-expanded-row:hover a {
          color: inherit !important;
        }

        .voirlesgroupes_tableinner {
          .ant-spin-nested-loading {

            //background:#f00;
            .ant-table-content {
              table {
                .ant-table-thead {
                  tr {
                    th:first-child {
                      text-align: center;
                      width: 33%;
                    }

                    th:nth-child(2) {
                      text-align: center;
                      width: 33%;
                    }

                    th:last-child {
                      text-align: center;
                      width: 33%;
                    }
                  }
                }

                .ant-table-tbody {
                  tr {
                    td {
                      float: left !important;
                      border: none !important;
                      border-bottom: 1px solid #f0f0f0 !important;
                    }

                    td:first-child {
                      text-align: center;
                      width: 33%;
                    }

                    td:nth-child(2) {
                      text-align: center;
                      width: 33%;
                    }

                    td:last-child {
                      text-align: center;
                      width: 33%;
                    }

                  }

                  tr:hover td,
                  tr:hover a {
                    color: #1890ff !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.profile_section {

  // background-color: #019BCD !important;
  // height: auto !important;
  .adminprofile-form {
    float: left;
    width: 100%;
    background: #fff;
    padding: 2em 3em 1em 15em;

    h2 {
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      margin-bottom: 15px;
      color: rgba(0, 0, 0, 0.85);
    }

    .topalertonsuccess {
      top: 2em;

      .circlechcek {
        svg {
          fill: #52c41a;
        }
      }

      .close-alert {
        position: absolute;
        right: 0;
        top: 15px;

        svg {
          fill: #595959;
        }
      }
    }

    label {
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
    }
  }
}

.secretaryprofile-form {
  h2 {
    margin-bottom: 0px;
  }

  .topalertonsuccess {
    top: 2em;

    .circlechcek {
      svg {
        fill: #52c41a;
      }
    }

    .close-alert {
      position: absolute;
      right: 0;
      top: 15px;

      svg {
        fill: #595959;
      }
    }
  }

  label {
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
  }
}

.passwordbottom {
  margin-bottom: 10px;

  .ant-form-item {
    margin-bottom: 10px;
  }
}

.constatsencours_wrapper {
  .tabletop-filter-section {
    float: left;
    width: 100%;
    margin-bottom: 1em;

    .ant-row {
      .ant-col:nth-child(3) {
        text-align-last: left;

        a {
          text-align-last: end;
        }
      }
    }

    .table-search-input {
      float: right;
      width: 30%;
      padding-right: 30px;
    }

    .searchiconalign {
      position: absolute;
      right: 10px;
      z-index: 9;
    }
  }
}

.select-user-type {
  margin-bottom: 24px;
}

.createmyaccount_form {
  .ant-form-item {
    margin-bottom: 0px;
  }
}

.rbc-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px;
}

.retrocessionadminreports_wrapper {
  .form_tableouter_wrapper {
    h2 {
      margin: 1em 0;
    }

    .table-rows-select-one {
      width: 100%;
      margin-right: 1.5em;
    }

    .table-rows-select-two {
      width: 100%;
      margin-left: 15px;
      margin-right: 1.5em;
    }

    .table-toptable-layout {
      float: right;
      width: 400px;
      background: #fff;
      margin-bottom: 3em;
      position: relative;
      top: -50px;

      .left-section {
        float: left;
        width: 70%;
        border-right: 1px solid #e8e8e8;

        .left-section-top-title {
          background: #fafafa;
          padding: 1em;
          float: left;
          width: 100%;
          position: relative;

          .top-title-left-section {
            float: left;
            width: 70%;
            text-align: center;
            font-weight: 900;
            font-size: 14px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.65);
          }

          .top-title-right-section {
            float: left;
            width: 30%;
            text-align: center;
            font-weight: 900;
            font-size: 14px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.65);
          }
        }

        .left-section-content {
          background: #ffffff;
          border-top: 1px solid #e8e8e8;
          padding: 0.5em;
          float: left;
          width: 100%;

          .left-section-content-left {
            float: left;
            width: 70%;
            text-align: center;
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
            color: #595959;
          }

          .left-section-content-right {
            float: left;
            width: 30%;
            text-align: center;
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
            color: #595959;
          }
        }
      }

      .right-section {
        float: left;
        width: 30%;

        .left-section-top-title {
          background: #fafafa;
          padding: 1em;
          float: left;
          width: 100%;

          .top-title-right-section {
            float: left;
            width: 100%;
            text-align: center;
            font-weight: 900;
            font-size: 14px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.65);
          }
        }

        .left-section-content {
          background: #ffffff;
          border-top: 1px solid #e8e8e8;
          padding: 0.5em;
          float: left;
          width: 100%;
          height: 111px;
          display: flex;
          align-items: flex-end;
          justify-content: center;

          .left-section-content-total {
            float: left;
            width: 100%;
            text-align: center;
            font-weight: bold;
            font-size: 16px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.65);
          }
        }
      }
    }
  }
}

.containerBalif {
  width: 80%;
}

.facturation_wrapper {
  .table-toptable-layout {
    float: right;
    width: 400px;
    background: #fff;
    margin-bottom: 3em;
    position: relative;
    top: -50px;

    .left-section {
      float: left;
      width: 70%;
      border-right: 1px solid #e8e8e8;

      .left-section-top-title {
        background: #fafafa;
        padding: 1em;
        float: left;
        width: 100%;

        .top-title-left-section {
          float: left;
          width: 70%;
          text-align: center;
          font-weight: 900;
          font-size: 14px;
          line-height: 22px;
          color: rgba(0, 0, 0, 0.65);
        }

        .top-title-right-section {
          float: left;
          width: 30%;
          text-align: center;
          font-weight: 900;
          font-size: 14px;
          line-height: 22px;
          color: rgba(0, 0, 0, 0.65);
        }
      }

      .left-section-content {
        background: #ffffff;
        border-top: 1px solid #e8e8e8;
        padding: 0.5em;
        float: left;
        width: 100%;

        .left-section-content-left {
          float: left;
          width: 70%;
          text-align: center;
          font-weight: normal;
          font-size: 14px;
          line-height: 22px;
          color: #595959;
        }

        .left-section-content-right {
          float: left;
          width: 30%;
          text-align: center;
          font-weight: normal;
          font-size: 14px;
          line-height: 22px;
          color: #595959;
        }
      }
    }

    .right-section {
      float: left;
      width: 30%;

      .left-section-top-title {
        background: #fafafa;
        padding: 1em;
        float: left;
        width: 100%;

        .top-title-right-section {
          float: left;
          width: 100%;
          text-align: center;
          font-weight: 900;
          font-size: 14px;
          line-height: 22px;
          color: rgba(0, 0, 0, 0.65);
        }
      }

      .left-section-content {
        background: #ffffff;
        border-top: 1px solid #e8e8e8;
        padding: 0.5em;
        float: left;
        width: 100%;
        height: 111px;
        display: flex;
        align-items: flex-end;
        justify-content: center;

        .left-section-content-total {
          float: left;
          width: 100%;
          text-align: center;
          font-weight: bold;
          font-size: 16px;
          line-height: 22px;
          color: rgba(0, 0, 0, 0.65);
        }
      }
    }
  }
}

.retrocessionadminreports_wrapper {
  .constatsencours-tables {
    position: relative;
    top: -50px;
  }

  .ant-table-content {
    table {
      .ant-table-thead {
        tr {
          th:first-child {}

          th:nth-child(2) {
            width: 12%;
            padding: 5px;
          }

          th:nth-child(3) {
            width: 15%;
            padding: 5px;
          }

          th:nth-child(4) {
            width: 17%;
            padding: 5px;
          }

          th:nth-child(5) {
            width: 5%;
            padding: 5px;
          }

          th:nth-child(6) {
            text-align: center;
            padding: 10px;
            width: 5%;
          }

          th:nth-child(7) {
            text-align: center;
            padding: 5px;
            width: 5%;
          }

          th:nth-child(8) {
            text-align: center;
            padding: 5px;
            width: 5%;
          }

          th:nth-child(9) {
            text-align: center;
            padding: 5px;
            width: 5%;
          }

          th:nth-child(10) {
            text-align: center;
            padding: 5px;
            width: 5%;
          }

          th:nth-child(11) {
            text-align: center;
            padding: 5px;
            width: 5%;
          }

          th:last-child {
            padding: 5px;
            width: 5%;
          }
        }
      }

      .ant-table-tbody {
        tr {
          td:first-child {}

          td:nth-child(2) {
            width: 12%;
            padding: 5px;
          }

          td:nth-child(3) {
            width: 15%;
            padding: 5px;
          }

          td:nth-child(4) {
            width: 17%;
            padding: 5px;
          }

          td:nth-child(5) {
            text-align: center;
            width: 5%;
            padding: 5px;
          }

          td:nth-child(6) {
            text-align: center;
            width: 10%;
            padding: 5px;
          }

          td:nth-child(7) {
            text-align: center;
            width: 5%;
            padding: 5px;
          }

          td:nth-child(8) {
            text-align: center;
            width: 5%;
            padding: 5px;
          }

          td:nth-child(9) {
            text-align: center;
            width: 5%;
            padding: 5px;
          }

          td:nth-child(10) {
            text-align: center;
            width: 5%;
            padding: 5px;
          }

          td:nth-child(11) {
            text-align: center;
            width: 5%;
            padding: 5px;
          }

          td:last-child {
            width: 5%;
            //font-size: 11px;
            padding: 5px;
            text-align: center;

            svg {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

.retrocessionadminreports-table {
  .ant-table-content {
    table {
      .ant-table-thead {
        tr {
          th:first-child {
            text-align: left;
            width: 1%;
          }

          th:nth-child(2) {
            text-align: left;
            width: 7%;
          }

          th:nth-child(3) {
            text-align: left;
            width: 8%;
          }

          th:nth-child(4) {
            text-align: left;
            width: 10%;
          }

          th:nth-child(5) {
            text-align: left;
            width: 15%;
          }

          th:nth-child(6) {
            text-align: left;
            width: 10%;
          }

          th:nth-child(7) {
            text-align: left;
            width: 8%;
          }

          th:nth-child(8) {
            text-align: center;
            width: 8%;
          }

          th:nth-child(9) {
            text-align: center;
            width: 8%;
          }

          th:nth-child(10) {
            text-align: center;
            width: 5%;
          }

          th:nth-child(11) {
            text-align: center;
            width: 5%;
          }

          th:last-child {
            text-align: center;
            width: 5%;
          }
        }
      }

      .ant-table-tbody {
        tr {
          td:first-child {
            text-align: left;
            width: 1%;
          }

          td:nth-child(2) {
            text-align: left;
            width: 7%;
          }

          td:nth-child(3) {
            text-align: left;
            width: 8%;
          }

          td:nth-child(4) {
            text-align: left;
            width: 10%;
          }

          td:nth-child(5) {
            text-align: left;
            width: 15%;
          }

          td:nth-child(6) {
            text-align: left;
            width: 10%;
          }

          td:nth-child(7) {
            text-align: left;
            width: 8%;
          }

          td:nth-child(8) {
            text-align: center;
            width: 8%;
          }

          td:nth-child(9) {
            text-align: center;
            width: 8%;
          }

          td:nth-child(10) {
            text-align: center;
            width: 5%;
          }

          td:nth-child(11) {
            text-align: center;
            width: 5%;
          }

          td:last-child {
            text-align: center;
            width: 5%;
          }
        }
      }

      .ant-spin-nested-loading {
        tbody {
          .ant-table-cell {
            .tabletoggle-section {
              .tabletoggle-section-title {
                .ant-space-item {
                  width: 70% !important;

                  h2 {
                    text-align: left;
                  }
                }
              }

              .tabletoggle-section-list-mask {
                .tabletoggle-section-list {
                  .tabletoggle-section-info {
                    h2 {
                      text-align: left;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .table_client_name {
    color: rgba(0, 0, 0, 0.85);
    text-decoration: underline;
  }
}

.retrocessionadminreports-modal {
  width: 700px !important;

  .ant-modal-content {
    width: 100%;
    height: 100%;

    .ant-modal-body {
      width: 100%;
      min-height: 20vh;
    }

    .reply-btn_wrapper {
      width: 100%;

      .reply-btn {
        float: right;
        min-width: 120px;
        padding: 5px 12px;
        background: #ffffff;
        border: 1px solid #40a9ff;
        box-sizing: border-box;
        font-weight: normal;
        font-size: 14px;
        color: #40a9ff;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
      }
    }

    .viewoldmessage_wrapper {
      width: 88%;
      border-top: 1px solid #e9e9e9;
      margin: 0 auto;
      margin-top: 3em;
      margin-left: 75px;
    }

    .ant-comment-content-author-name {
      float: left;
      width: 100%;
    }

    .comment-left-wrapper {
      .comment_avatar {
        margin-left: 10px;
      }

      .comment_avt_name {
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: #1890ff;
        position: relative;
        top: 5px;
        left: 12px;
      }
    }

    .comment-right-wrapper {
      .comment_avt_date {
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        float: right;
        position: relative;
        top: 10px;
        color: rgba(0, 0, 0, 0.65);
      }
    }
  }

  .ant-comment-content-author {
    margin-top: 1em;
    border-bottom: 1px solid #e9e9e9;
    padding-bottom: 4px;
  }

  .comment_wrapper {
    // padding: 0px 4em;
    padding: 0px 0.5rem 0 4em;

    .ant-comment-inner {
      padding: 0px;
    }
  }

  .modal_title {
    margin-left: 2em;
  }
}

.billssecretaries-table {
  .ant-table-content {
    table {
      .ant-table-thead {
        tr {
          th:first-child {
            text-align: left;
            width: 20%;
          }

          th:nth-child(2) {
            text-align: left;
            width: 25%;
          }

          th:nth-child(3) {
            text-align: left;
            width: 25%;
          }

          th:nth-child(4) {
            text-align: left;
            width: 10%;
          }

          th:nth-child(5) {
            text-align: left;
            width: 5%;
          }

          th:last-child {
            text-align: left;
            width: 5%;
          }
        }
      }

      .ant-table-tbody {
        tr {
          td:first-child {
            text-align: left;
            margin: auto;
            width: 20%;
          }

          td:nth-child(2) {
            text-align: left;
            width: 25%;
          }

          td:nth-child(3) {
            text-align: left;
            width: 25%;
          }

          td:nth-child(4) {
            text-align: left;
            width: 10%;
          }

          td:nth-child(5) {
            text-align: left;
            width: 5%;
          }

          td:last-child {
            text-align: left;
            width: 5%;
          }
        }
      }
    }
  }
}

.commontopfourwrapper {
  h2 {
    a {
      font-size: 15px;
      color: #595959;
      font-weight: 500;
      //margin-top: 12px;
      //float: left;
      width: 100%;

      &:hover {
        color: #595959;
        text-decoration: none;
      }
    }
  }
}

.commontopfivewrapper {
  h2 {
    a {
      font-size: 15px;
      color: #595959;
      font-weight: 500;
      //margin-top: 12px;
      //float: left;
      width: 100%;

      &:hover {
        color: #595959;
        text-decoration: none;
      }
    }
  }
}

table {
  .ant-table-tbody {
    word-break: break-all;
  }
}

.createanalert-wrapper {
  float: left;
  width: 100%;
  padding: 2em;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;

  .createanalert-mask {
    width: 468px;
    float: none;
    margin: 0 auto;
  }

  .createanalert-form {
    .ant-picker {
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;

      .ant-picker-input {
        width: 256px;
        height: 32px;
      }
    }

    .ant-form-item {
      textarea.ant-input {
        height: 88px;
        resize: none;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border: 1px solid #d9d9d9;
      }
    }

    .register-btn-mask {
      .validate-btn {
        min-width: 90px;
        height: 40px;
        margin-right: 10px;
        background: #40a9ff;
        border: 1px solid #40a9ff;
        font-size: 14px;
        color: #fff;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
      }

      .cancel-btn {
        min-width: 90px;
        height: 40px;
        margin-right: 10px;
        border: 1px solid #d9d9d9;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
      }
    }
  }
}

.viewnews_wrapper {
  float: left;
  width: 100%;
  padding: 80px 30px;

  .commontopfourwrapper {
    margin-top: 7.2em;
  }

  .form_viewnews_wrapper {
    h2 {
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      color: rgba(0, 0, 0, 0.85);
      margin: 2em 0 1em 0;
    }

    .tabletop-filter-section {
      float: left;
      width: 100%;
      margin-bottom: 15px;

      .table-search-input {
        float: right;
        width: 256px;
        height: 39px;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
      }

      .searchiconalign {
        position: absolute;
        right: 10px;
        z-index: 9;
      }

      .ant-select-show-arrow {
        .ant-select-selector {
          height: 40px;
          padding: 5px 11px;
          border-radius: 4px;
          -webkit-border-radius: 4px;
          -moz-border-radius: 4px;
        }
      }
    }
  }

  .viewnews-table {
    .ant-table-content {
      table {
        .ant-table-thead {
          tr {
            th:first-child {
              text-align: center;
              width: 25%;
            }

            th:nth-child(2) {
              text-align: left;
              width: 25%;
            }

            th:nth-child(3) {
              text-align: left;
              width: 25%;
            }

            th:last-child {
              text-align: center;
              width: 25%;
            }
          }
        }

        .ant-table-tbody {
          tr {
            td:first-child {
              text-align: center;
              width: 25%;
            }

            td:nth-child(2) {
              text-align: left;
              width: 25%;
            }

            td:nth-child(3) {
              text-align: left;
              width: 25%;
            }

            td:last-child {
              text-align: center;
              width: 25%;
            }
          }
        }
      }
    }
  }
}

.viewnewssecretarie_wrapper {
  float: none !important;
  margin: 0 auto !important;
  width: 79% !important;
  background: none !important;

  .commontopfourwrapper {
    margin-top: 7.2em;
  }

  .form_viewnews_wrapper {
    h2 {
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      color: rgba(0, 0, 0, 0.85);
      margin: 2em 0 1em 0;
    }

    .tabletop-filter-section {
      float: left;
      width: 100%;
      margin-bottom: 15px;

      .table-search-input {
        float: right;
        width: 256px;
        height: 39px;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
      }

      .searchiconalign {
        position: absolute;
        right: 10px;
      }

      .ant-select-show-arrow {
        .ant-select-selector {
          height: 40px;
          padding: 5px 11px;
          border-radius: 4px;
          -webkit-border-radius: 4px;
          -moz-border-radius: 4px;
        }
      }
    }
  }

  .viewnews-table {
    .ant-table-content {
      table {
        .ant-table-thead {
          tr {
            th:first-child {
              text-align: center;
              width: 25%;
            }

            th:nth-child(2) {
              text-align: left;
              width: 25%;
            }

            th:nth-child(3) {
              text-align: left;
              width: 25%;
            }

            th:last-child {
              text-align: center;
              width: 25%;
            }
          }
        }

        .ant-table-tbody {
          tr {
            td:first-child {
              text-align: center;
              width: 25%;
            }

            td:nth-child(2) {
              text-align: left;
              width: 25%;
            }

            td:nth-child(3) {
              text-align: left;
              width: 25%;
            }

            td:last-child {
              text-align: center;
              width: 25%;
            }
          }
        }
      }
    }
  }
}

.retrocessionsendbill_wrapper {
  .form_tableouter_wrapper {
    h2 {
      margin-top: 2em;
    }

    .table-rows-select-one {
      width: 100%;
    }

    .table-rows-select-two {
      width: 100%;
      margin-left: 15px;
    }

    .table-toptable-layout {
      float: right;
      width: 400px;
      background: #fff;
      margin-bottom: 4em;

      .left-section {
        float: left;
        width: 70%;
        border-right: 1px solid #e8e8e8;

        .left-section-top-title {
          background: #fafafa;
          padding: 1em;
          float: left;
          width: 100%;

          .top-title-left-section {
            float: left;
            width: 70%;
            text-align: center;
            font-weight: 900;
            font-size: 14px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.65);
          }

          .top-title-right-section {
            float: left;
            width: 30%;
            text-align: center;
            font-weight: 900;
            font-size: 14px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.65);
          }
        }

        .left-section-content {
          background: #ffffff;
          border-top: 1px solid #e8e8e8;
          padding: 0.5em;
          float: left;
          width: 100%;

          .left-section-content-left {
            float: left;
            width: 70%;
            text-align: center;
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
            color: #595959;
          }

          .left-section-content-right {
            float: left;
            width: 30%;
            text-align: center;
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
            color: #595959;
          }
        }
      }

      .right-section {
        float: left;
        width: 30%;

        .left-section-top-title {
          background: #fafafa;
          padding: 1em;
          float: left;
          width: 100%;

          .top-title-right-section {
            float: left;
            width: 100%;
            text-align: center;
            font-weight: 900;
            font-size: 14px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.65);
          }
        }

        .left-section-content {
          background: #ffffff;
          border-top: 1px solid #e8e8e8;
          padding: 0.5em;
          float: left;
          width: 100%;
          height: 111px;
          display: flex;
          align-items: flex-end;
          justify-content: center;

          .left-section-content-total {
            float: left;
            width: 100%;
            text-align: center;
            font-weight: bold;
            font-size: 16px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.65);
          }
        }
      }
    }
  }

  .factures-wrapper {
    float: right;
    width: 268px;

    .toptitlesection {
      float: left;
      width: 100%;
      border-bottom: 1px solid #e9e9e9;
      margin-bottom: 10px;
      padding-bottom: 5px;

      .toptitleleft {
        float: left;
        width: 50%;
        text-align: left;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        color: #595959;
        text-transform: uppercase;
      }

      .toptitleright {
        float: left;
        width: 50%;
        text-align: right;
        font-weight: normal;

        a {
          font-size: 14px;
          line-height: 22px;
          color: #1890ff;
          text-decoration: underline;
        }
      }
    }

    .submitinvoice {
      float: left;
      width: 100%;

      // padding: 1em;
      // background: rgba(255, 255, 255, 0.41);
      // border: 1px dashed #D1D5D9;
      // box-sizing: border-box;
      // border-radius: 4px;
      .factureuploadfield {
        .anticon-plus {
          svg {
            width: 15px;
          }
        }

        .ant-upload-text {
          font-weight: normal;
          font-size: 14px;
          line-height: 22px;
          color: #1890ff;
        }

        .ant-upload-hint {
          font-weight: normal;
          font-size: 11px;
          line-height: 22px;
          color: rgba(0, 0, 0, 0.5);
        }

        .ant-upload-drag-icon {
          margin-bottom: 0px;
        }
      }
    }
  }

  .tabletop-filter-section {
    h2 {
      margin: 0px 0px 1em 0px;
    }
  }

  .content-bil-table {
    @media screen and (max-width: "1143px") {
      margin-top: 50%;
      background-color: #146fc5;
    }
  }

  .retrocessionsendbill-table {
    .ant-table-content {
      table {
        .ant-table-thead {
          tr {
            th:first-child {
              text-align: center;
              width: 1%;
            }

            th:nth-child(2) {
              text-align: left;
              width: 10%;
            }

            th:nth-child(3) {
              text-align: left;
              width: 10%;
            }

            th:nth-child(4) {
              text-align: left;
              width: 30%;
            }

            th:nth-child(5) {
              text-align: left;
              width: 12%;
            }

            th:nth-child(6) {
              text-align: left;
              width: 12%;
            }

            th:nth-child(7) {
              text-align: center;
              width: 10%;
            }

            th:nth-child(8) {
              text-align: center;
              width: 10%;
            }

            th:last-child {
              text-align: center;
              width: 5%;
            }
          }
        }

        .ant-table-tbody {
          tr {
            td:first-child {
              text-align: center;
              width: 1%;
            }

            td:nth-child(2) {
              text-align: left;
              width: 10%;
            }

            td:nth-child(3) {
              text-align: left;
              width: 10%;
            }

            td:nth-child(4) {
              text-align: left;
              width: 30%;
            }

            td:nth-child(5) {
              text-align: center;
              width: 12%;
            }

            td:nth-child(6) {
              text-align: left;
              width: 12%;
            }

            td:nth-child(7) {
              text-align: center;
              width: 10%;
            }

            td:nth-child(8) {
              text-align: center;
              width: 10%;
            }

            td:last-child {
              text-align: center;
              width: 5%;
            }
          }
        }
      }
    }

    .ant-table-expanded-row {
      .ant-spin-nested-loading {
        .ant-table-content {
          .ant-table-cell {
            width: 50%;

            .tabletoggle-section {
              .ant-space-item {
                width: 100% !important;

                h2 {
                  text-align: left;
                }
              }

              .tabletoggle-section-list-mask {
                .tabletoggle-section-list {
                  .tabletoggle-section-info {
                    h2 {
                      text-align: left;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.createanews-wrapper {
  .form_outer_onlyscreenwrapper {
    margin-top: 4em;
    padding: 2em;

    h2 {
      width: 80%;
      float: none;
      margin: 0 auto;
    }

    .form_onlyscreenwrapper {
      width: 55%;
      margin: 0 auto;
      padding-top: 4em;
      float: none;

      .create_news {
        .createnews-editor {
          margin-top: 0.5em;
          width: 100%;

          .ck-content {
            height: 400px;
          }
        }

        .news-headline {
          width: 70%;
        }

        .register-btn-mask {

          //margin: 0px;
          .ant-form-item-control-input-content {
            text-align: left;

            .validate-btn {
              min-width: 90px;
              height: 40px;
              margin-right: 10px;
              background: #40a9ff;
              border: 1px solid #40a9ff;
              font-size: 14px;
              color: #fff;
              border-radius: 4px;
              -webkit-border-radius: 4px;
              -moz-border-radius: 4px;
            }

            .cancel-btn {
              min-width: 90px;
              height: 40px;
              margin-right: 10px;
              border: 1px solid #d9d9d9;
              font-size: 14px;
              color: rgba(0, 0, 0, 0.65);
              border-radius: 4px;
              -webkit-border-radius: 4px;
              -moz-border-radius: 4px;
            }
          }
        }
      }
    }
  }
}

// .createreport_wrapper {
//   float: none !important;
//   margin: 0 auto !important;
//   width: 79% !important;
//   padding-top: 112px;
//   background: none !important;
// }

.mybillsgailif_wrapper {
  float: none !important;
  margin: 0 auto !important;
  background: none !important;

  .form_outer_wrapper {
    float: left;
    width: 100% !important;
    padding: 0px !important;
  }
}

.createreport_wrapper {
  .toptitlewithunderline {
    width: 70%;
    float: none;
    margin: 0 auto;
    margin-bottom: 1.5em;
    border-bottom: 2.5px solid #e9e9e9;
  }
}

.createreport_form {
  width: 40%;
  float: none;
  margin: 0 auto;

  .ant-form-item-label {
    float: left;
    width: 100%;
    text-align: left;

    label {
      font-weight: bold;
      font-size: 14px;
      line-height: 22px;
      color: #595959;
    }
  }

  .input-bottom-text {
    font-style: italic;
    font-weight: normal;
    font-size: 13px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.3);
  }

  .createreportcomment {
    .form_input_field {
      height: 88px;
      box-sizing: border-box;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
    }
  }
}

.register-btn-mask {
  text-align: center;

  .validate-btn {
    min-width: 90px;
    height: 40px;
    margin-right: 10px;
    background: #40a9ff;
    border: 1px solid #40a9ff;
    font-size: 14px;
    color: #fff;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
  }

  .cancel-btn {
    min-width: 90px;
    height: 40px;
    margin-right: 10px;
    border: 1px solid #d9d9d9;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
  }
}

.viewsecretarie-modal {
  width: 742px !important;

  h2 {
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    color: #595959;
    margin-bottom: 0px;

    .modalflaticon {
      position: relative;
      left: -15px;
    }
  }

  .viewsecretarie-date {
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #595959;
    margin-bottom: 12px;
  }

  p {
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.65);
  }

  .ant-modal-body {
    padding: 25px 40px 25px 60px !important;

    .modalflaticon {
      position: relative;
      color: #d4380d;
      top: 28px;
      font-weight: bold;
      left: -25px;
    }

    .modalflaticon-empty {
      position: relative;
      top: 28px;
      font-weight: bold;
      left: -25px;
    }
  }
}

.modalflaticon {
  position: relative;
  font-weight: bold;
  color: #d4380d;
}

.modalflaticon-empty {
  position: relative;
  font-weight: bold;
}

.ant-modal-content {
  .ant-modal-close {
    .ant-modal-close-x {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 1px solid #323232;
      padding: 0px;
      position: relative;
      top: 15px;
      right: 15px;

      span {
        font-weight: normal;
        font-size: 8px;
        line-height: 18px;
        color: #323232;
        position: absolute;
        top: 1px;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
    }
  }

  .ant-modal-body {
    padding: 25px 40px;
  }
}

table {
  tbody {
    .ant-spin-nested-loading {
      tbody.ant-table-tbody {
        .ant-table-cell {
          .tabletoggle-section {
            .tabletoggle-section-title {
              .ant-space-item {
                h2 {
                  text-align: left;
                }
              }
            }

            .tabletoggle-section-list-mask {
              .tabletoggle-section-list {
                .tabletoggle-section-info {
                  h2 {
                    text-align: left;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.secretarydashboard-table {
  .ant-spin-nested-loading {
    .ant-table-tbody {
      .expandedrow-leftsection {
        .tabletoggle-section-title {
          flex-direction: column !important;

          .ant-space-item:first-child {
            float: left;
            width: 100% !important;
            margin-bottom: 10px;

            .secretarytop-btn {
              float: left;
              width: 100%;

              .innertable-title-btn {
                float: right;
                border: 1px solid #40a9ff;
                box-sizing: border-box;
                border-radius: 4px;
                font-size: 14px;
                color: #40a9ff;
                font-weight: normal;
                padding: 3px 5px;
                margin-left: 15px;

                svg {
                  width: 18px;
                  padding-top: 5px;
                  padding-right: 2px;
                }
              }
            }
          }

          .ant-space-item:last-child {
            float: left;
            width: 100% !important;

            h2 {
              margin-bottom: 0px;
            }
          }
        }
      }

      .expandedrow-rightsection {
        .tabletoggle-section-title {
          flex-direction: row !important;

          .ant-space-item:first-child {
            float: left;
            width: 70% !important;
          }

          .ant-space-item:last-child {
            float: left;
            width: 30% !important;

            .innertable-title-btn {
              float: right;
              border: 1px solid #40a9ff;
              box-sizing: border-box;
              border-radius: 4px;
              font-size: 14px;
              color: #40a9ff;
              font-weight: normal;
              padding: 3px 5px;
              margin-left: 10px;

              svg {
                width: 18px;
                padding-top: 5px;
                padding-right: 2px;
              }

              span {
                margin-top: 0px !important;
              }
            }
          }
        }
      }
    }
  }
}

.tabletoggle-section-list-mask {
  .tabletoggle-center-btn-section {
    float: left;
    width: 100%;
  }

  .innertable_boldtext {
    float: left;
    width: 100%;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    text-align: left;
    margin-top: 12px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.65);
  }

  .center-btn {
    //float: left;
    width: 100%;
    //text-align: center;
    display: flex;
    justify-content: center;

    .innertable-title-btn {
      float: none;
      border: 1px solid #40a9ff;
      box-sizing: border-box;
      border-radius: 4px;
      font-size: 14px;
      color: #40a9ff;
      font-weight: normal;
      padding: 3px 5px;
      display: inline-block;
      margin: 2em 0 1em 0;

      svg {
        width: 18px;
        padding-top: 1px;
        padding-right: 2px;
      }

      span {
        margin-top: 0px !important;
      }
    }
  }

  .table-statu-fact {
    float: left;
    width: 100%;

    h2 {
      text-align: left;
      text-transform: uppercase;
      margin-bottom: 0 !important;
    }

    .elements-retournes-wrapper {
      float: left;
      width: 100%;

      .totalnofoimagephotos {
        float: left;
        width: 100%;
        margin: 2em 0 0.5em 0;

        .imagephotos-form {
          width: 70%;
          float: left;

          .imagephotos-row {
            float: left;
            width: 100%;

            .elements-retournes-text {
              float: left;
              width: 60%;
              padding-top: 5px;
            }

            .imagephotos-count {
              float: left;
              width: 40%;

              .ant-form-item {
                margin-bottom: 0px;
              }
            }
          }
        }
      }
    }
  }

  .commentaire-form {
    float: left;
    width: 100%;

    .commentaire-textarea {
      float: left;
      width: 80%;

      textarea {
        border: 1px solid #d9d9d9;
        box-sizing: border-box;
        border-radius: 4px;
        height: 118px;
        margin-top: 1em;
      }
    }

    .commentaire-btn {
      float: left;
      width: 20%;
      height: 132px;
      display: flex;
      justify-content: center;
      align-items: flex-end;

      .envoyerbtn {
        background: #40a9ff;
        border: medium none;
        font-weight: normal;
        color: #ffffff;
        font-size: 14px;
        line-height: 22px;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
      }
    }
  }
}

.create_account.rigisteruser {
  .validatestar {
    .ant-form-item-label {
      &::before {
        content: "*";
        color: #ff4d4f;
        margin-right: 4px;
      }
    }
  }

  .profile_uploadimg_wrapper {
    .uploadimg-wrapper {
      margin-top: 28px;
      margin-left: 70px;
    }
  }
}

.creerungroupe_form {
  .validatestar {
    .ant-form-item-label {
      &::before {
        content: "*";
        color: #ff4d4f;
        margin-right: 4px;
      }
    }
  }
}

.secretarymybills_wrapper {
  .tabletop-filter-section {
    float: left;
    width: 100%;
    margin-bottom: 2em;
  }

  .billssecretaries-table {
    .ant-table-content {
      table {
        .ant-table-thead {
          tr {
            th:first-child {
              text-align: center;
              width: 10%;
            }

            th:nth-child(2) {
              text-align: center;
              width: 18%;
            }

            th:nth-child(3) {
              text-align: center;
              width: 18%;
            }

            th:nth-child(4) {
              text-align: center;
              width: 10%;
            }

            th:nth-child(5) {
              text-align: center;
              width: 25%;
            }

            th:last-child {
              text-align: center;
              width: 12%;
            }
          }
        }

        .ant-table-tbody {
          tr {
            td:first-child {
              text-align: center;
              width: 10%;
            }

            td:nth-child(2) {
              text-align: center;
              width: 18%;
            }

            td:nth-child(3) {
              text-align: center;
              width: 18%;
            }

            td:nth-child(4) {
              text-align: center;
              width: 10%;
            }

            td:nth-child(5) {
              text-align: center;
              width: 25%;
            }

            td:last-child {
              text-align: center;
              width: 12%;
            }
          }
        }
      }
    }
  }
}

.facturation-table {
  .ant-table-content {
    table {
      position: relative;
      top: -50px;

      .ant-table-thead {
        tr {
          th:first-child {
            text-align: center;
            width: 15%;
          }

          th:nth-child(2) {
            text-align: center;
            width: 10%;
          }

          th:nth-child(3) {
            text-align: center;
            width: 10%;
          }

          th:nth-child(4) {
            text-align: center;
            width: 10%;
          }

          th:nth-child(5) {
            text-align: center;
            width: 10%;
          }

          th:nth-child(6) {
            text-align: center;
            width: 10%;
          }

          th:nth-child(7) {
            text-align: center;
            width: 10%;
          }

          th:nth-child(8) {
            text-align: center;
            width: 10%;
          }

          th:nth-child(9) {
            text-align: center;
            width: 7%;
          }

          th:last-child {
            text-align: center;
            width: 8%;
          }
        }
      }

      .ant-table-tbody {
        tr {
          td:first-child {
            text-align: center;
            width: 15%;
            text-decoration: underline;
          }

          td:nth-child(2) {
            text-align: center;
            width: 10%;
          }

          td:nth-child(3) {
            text-align: center;
            width: 10%;
          }

          td:nth-child(4) {
            text-align: center;
            width: 10%;
          }

          td:nth-child(5) {
            text-align: center;
            width: 10%;
            text-decoration: underline;
          }

          td:nth-child(6) {
            text-align: center;
            width: 10%;

            .anticon svg {
              fill: #333;
              width: 20px;
              height: 16px;
            }

            .readonly {
              opacity: 0.5;
            }
          }

          td:nth-child(7) {
            text-align: center;
            width: 10%;

            .anticon svg {
              fill: #333;
              width: 20px;
              height: 16px;
            }
          }

          td:nth-child(8) {
            text-align: center;
            width: 10%;

            .anticon svg {
              fill: #333;
              width: 20px;
              height: 18px;
            }
          }

          td:nth-child(9) {
            text-align: center;
            width: 7%;

            .anticon svg {
              fill: #333;
              width: 20px;
              height: 18px;
            }
          }

          td:last-child {
            text-align: center;
            width: 8%;
          }
        }
      }
    }
  }
}

.facturation-modal {
  width: 70vw !important;

  // height: 459px !important;
  .ant-modal-content {
    width: 100%;
    height: 100%;

    .ant-modal-body {
      overflow: hidden;
      height: 100%;

      .ant-table-wrapper {
        // max-height: 330px;
        margin-top: 1em;
        // overflow-y: scroll;
        // overflow: auto;
      }

      .modalfactur-btn {
        float: right;
        margin-top: 2em;
        background: #40a9ff;
        border: medium none;
        min-width: 81px;
        height: 32px;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;

        span {
          font-weight: normal;
          font-size: 14px;
          line-height: 22px;
          color: #ffffff;
          text-align: center;
        }
      }
    }
  }
}

.facturation-modal2 {
  width: auto !important;

  // height: 459px !important;
  .ant-modal-content {
    width: 100%;
    height: 100%;

    .ant-modal-body {
      overflow: hidden;
      height: 100%;

      .ant-table-wrapper {
        // max-height: 330px;
        margin-top: 1em;
        // overflow-y: scroll;
        // overflow: auto;
      }

      .modalfactur-btn {
        float: right;
        margin-top: 2em;
        background: #40a9ff;
        border: medium none;
        min-width: 81px;
        height: 32px;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;

        span {
          font-weight: normal;
          font-size: 14px;
          line-height: 22px;
          color: #ffffff;
          text-align: center;
        }
      }
    }
  }
}

.page1 {
  th {
    word-spacing: 100vw;
  }
}

.otherPages {
  th {
    word-spacing: 100vw;
  }
}

.facturationtable_table {
  .ant-table-content {
    table {
      .ant-table-thead {
        tr {
          th:first-child {
            text-align: center;
            width: 0%;
          }

          th:nth-child(2) {
            text-align: center;
            width: 5%;
            padding: 0;
          }

          th:nth-child(3) {
            text-align: center;
            width: 25%;
          }

          th:nth-child(4) {
            text-align: center;
            width: 25%;
          }

          th:nth-child(5) {
            text-align: center;
            width: 25%;
          }

          th:last-child {
            text-align: center;
            width: 20%;
          }
        }
      }

      .ant-table-tbody {
        tr {
          td:first-child {
            text-align: center;
            width: 0%;
          }

          td:nth-child(2) {
            text-align: center;
            width: 5%;
          }

          td:nth-child(3) {
            text-align: center;
            width: 25%;
          }

          td:nth-child(4) {
            text-align: center;
            width: 25%;
          }

          td:nth-child(5) {
            text-align: center;
            width: 25%;
          }

          td:last-child {
            text-align: center;
            width: 20%;
          }
        }
      }
    }
  }
}

.profile_section {

  // background-color: #019BCD !important;
  // height: auto !important;
  .adminprofile-form {
    float: left;
    width: 100%;
    background: #fff;
    padding: 2em 3em 1em 15em;

    h2 {
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      margin-bottom: 15px;
      color: rgba(0, 0, 0, 0.85);
    }

    .topalertonsuccess {
      top: 2em;

      .circlechcek {
        svg {
          fill: #52c41a;
        }
      }

      .close-alert {
        position: absolute;
        right: 0;
        top: 15px;

        svg {
          fill: #595959;
        }
      }
    }

    label {
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
    }
  }
}

.secretaryprofile-form {
  h2 {
    margin-bottom: 0px;
  }

  .topalertonsuccess {
    top: 2em;

    .circlechcek {
      svg {
        fill: #52c41a;
      }
    }

    .close-alert {
      position: absolute;
      right: 0;
      top: 15px;

      svg {
        fill: #595959;
      }
    }
  }

  label {
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
  }

  .ant-row.ant-form-item {
    margin-bottom: 0px;

    .ant-form-item-explain {
      min-height: inherit;
    }
  }
}

.emailtemplate_wrapper {
  float: left;
  width: 100%;
  padding-top: 6em;

  .emailtemplate_mask {
    width: 80%;
    float: none;
    margin: 0 auto;

    .emailtemplate_inner {
      float: left;
      width: 100%;
      background: #ffffff;

      .headertemplate {
        float: left;
        width: 100%;
        position: relative;

        .headertemplate_left {
          position: absolute;
          top: 0;
          left: 0;
        }

        .logocenter {
          float: left;
          width: 100%;
          text-align: center;
          padding: 2em;
        }

        .headertemplate_right {
          position: absolute;
          top: 0;
          right: 0;
        }
      }

      .emailtemplatecontent {
        float: left;
        width: 100%;
        padding: 3em 2em;
      }

      .footertemplate {
        float: left;
        width: 100%;
        position: relative;
        padding: 1.5em;

        .footertemplate_left {
          position: absolute;
          top: 0;
          left: 0;
        }

        .logocenter {
          float: left;
          width: 100%;
          text-align: center;
          padding: 1.2em 0;
          position: relative;
          z-index: 9;

          .footerrow-one {
            float: left;
            width: 33%;
            text-align: center;
          }

          .footerrow-two {
            float: left;
            width: 33%;
            text-align: center;

            span {
              margin-left: 15px;
            }
          }

          .footerrow-three {
            float: left;
            width: 33%;
            text-align: center;

            span {
              margin-left: 15px;
            }
          }
        }

        .footertemplate_right {
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }
  }
}

.secretarys_form {
  .ant-form-item {
    margin-bottom: 0px;
  }
}

.constatsencours_wrapper {
  float: left;
  width: 100%;

  .ant-spin-nested-loading {
    float: left;
    width: 100%;

    table {
      tbody {
        tr {
          td.ant-table-cell {
            .tabletoggle-section {
              .tabletoggle-section-list-mask {
                .table-statu-fact {
                  float: left;
                  width: 100%;

                  h2 {
                    margin-bottom: 1em !important;
                  }

                  .table-statu-fact-info {
                    float: left;
                    width: 100%;

                    .title {
                      span {
                        font-size: 12px;
                        padding-left: 8px;
                      }
                    }

                    .statut_facturation_wrapper {
                      .ant-form-item-has-success {
                        input[type="text"] {
                          text-align: center;
                        }
                      }
                    }
                  }

                  .envoyer-btn {
                    margin-top: 85px;
                    // padding: 0px !important;
                    min-width: 60px !important;
                    font-size: 13px !important;
                  }

                  .table-statu-fact-info-title {
                    .info-title {
                      .title-one {
                        margin-top: 5px;
                      }

                      .title-two {
                        margin-top: 12px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.btn-disabled {
  height: 32px;
  background: #d9d9d9;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border: medium none;
  font-weight: normal;
  font-size: 13px !important;
  line-height: 22px;
  color: #ffffff;
  min-width: 60px !important;
  padding: 0px 15px !important;
}

.voirlesgroupes_wrapper_mask {
  .innerpages_title {
    margin-bottom: 0px;
  }

  .tabletop_individual {
    display: flex;
    float: right;
    margin-bottom: 20px;
    width: max-content;
  }

  .table-search-input {
    float: right;
    width: 100%;
    padding-right: 30px;
    height: auto;
  }

  .searchiconalign {
    position: absolute;
    right: 10px;
    z-index: 9;
  }
}

.bailiffaccount_form {
  .ant-form-item-has-success {
    display: inherit !important;
  }

  .ant-upload-select-picture-card {
    .uploadimg-wrapper {
      top: 0;
      border: 1px solid #d1d1d1;
      border-radius: 50%;
      width: 120px !important;
      height: 120px !important;

      img {
        width: 100% !important;
        height: 100% !important;
        object-fit: cover;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
      }

      .uploadimg-icons {
        img {
          width: auto !important;
          height: auto !important;
        }
      }
    }
  }
}

.adminaccount_form {
  .passwordbottom {
    margin-top: 3px;

    .ant-form-item-has-success {
      display: flex !important;
    }
  }

  .ant-form-item-has-success {
    display: inherit !important;
    margin-top: 3px;
  }

  .ant-upload-select-picture-card {
    .uploadimg-wrapper {
      top: 0;
      left: 4em;
      border: 1px solid #d1d1d1;
      border-radius: 50%;
      width: 120px !important;
      height: 120px !important;

      img {
        width: 100% !important;
        height: 100% !important;
        object-fit: cover;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
      }

      .uploadimg-icons {
        img {
          width: auto !important;
          height: auto !important;
        }
      }
    }
  }
}

.createmyaccount_form {
  .ant-upload-select-picture-card {
    .uploadimg-wrapper {
      top: 0;
      left: 4em;
      border: 1px solid #d1d1d1;
      border-radius: 50%;
      width: 120px !important;
      height: 120px !important;

      img {
        width: 100% !important;
        height: 100% !important;
        object-fit: cover;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
      }

      .uploadimg-icons {
        img {
          width: auto !important;
          height: auto !important;
        }
      }
    }
  }
}

.adminprofile-form {
  .ant-upload-select-picture-card {
    .ant-upload {
      justify-content: left;
    }

    .uploadimg-wrapper {
      top: 0;
      left: 4em;
      border: 1px solid #d1d1d1;
      border-radius: 50%;
      width: 120px !important;
      height: 120px !important;

      img {
        width: 100% !important;
        height: 100% !important;
        object-fit: cover;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
      }

      .uploadimg-icons {
        img {
          width: auto !important;
          height: auto !important;
        }
      }
    }
  }

  .ant-form-item {
    margin-bottom: 5px;
  }

  .validate-btn {
    height: 30px;
    margin-top: 1em;
    min-width: 40px;
    font-size: 12px;
  }

  h3 {
    margin-top: 1.5em;
    font-size: 15px;
  }

  .ant-form-item-control-input-content .ant-input {
    padding: 6px 12px;
  }

  .ant-input-password {
    padding: 0px 12px;
  }
}

.bailiffprofile-form {
  .ant-form-item-control-input-content .ant-input {
    padding: 6px 12px;
  }

  .ant-input-password {
    padding: 0px 12px;
  }
}

.secretarys_form {
  .ant-upload-select-picture-card {
    .uploadimg-wrapper {
      top: 0;
      left: 4em;
      border: 1px solid #d1d1d1;
      border-radius: 50%;
      width: 120px !important;
      height: 120px !important;

      img {
        width: 100% !important;
        height: 100% !important;
        object-fit: cover;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
      }

      .uploadimg-icons {
        img {
          width: auto !important;
          height: auto !important;
        }
      }
    }
  }
}

.secretaryprofile-form {
  .ant-upload-select-picture-card {
    .ant-upload {
      justify-content: left;

      .uploadimg-wrapper {
        top: 0;
        left: 0;
        border: 1px solid #d1d1d1;
        border-radius: 50%;
        width: 120px !important;
        height: 120px !important;

        img {
          width: 100% !important;
          height: 100% !important;
          object-fit: cover;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
        }

        .uploadimg-icons {
          img {
            width: auto !important;
            height: auto !important;
          }
        }
      }
    }
  }

  .ant-row.ant-form-item {
    margin-bottom: 10px;
  }
}

.bailiffprofile-form {
  .profile_uploadimg_wrapper {
    h2 {
      margin-bottom: 0px;
    }
  }

  .ant-upload-select-picture-card {
    .ant-upload {
      justify-content: left;

      .uploadimg-wrapper {
        top: 0;
        left: 0;
        border: 1px solid #d1d1d1;
        border-radius: 50%;
        width: 120px !important;
        height: 120px !important;

        img {
          width: 100% !important;
          height: 100% !important;
          object-fit: cover;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
        }

        .uploadimg-icons {
          img {
            width: auto !important;
            height: auto !important;
          }
        }
      }
    }
  }

  .ant-row.ant-form-item {
    margin-bottom: 10px;

    .ant-form-item-control {
      .ant-form-item-explain {
        min-height: auto;
      }
    }
  }
}

.seetheaccount_wrapper_mask {
  .tabletop_individual {
    display: flex;
    float: right;
    margin-bottom: 20px;
    width: max-content;
  }

  .table-search-input {
    float: right;
    width: 100%;
    padding-right: 30px;
    height: auto;
  }

  .searchiconalign {
    position: absolute;
    right: 10px;
    z-index: 9;
  }
}

.detail-table-confirmation {
  tr {
    td {
      border: 1px solid gray;
      padding: 10px 15px !important;
    }

    td:nth-child(1) {
      // min-width: 300px;
      // max-width: 300px;
      // width: 300px;
      padding: 10px 15px;
      text-align: center;
    }

    td:nth-child(2) {
      // width: 50px !important;
      // text-align: center;
      padding: 10px 15px;
      text-align: center;
    }

    td:nth-child(3) {
      // width: 50px !important;
      // text-align: center;
      padding: 10px 15px;
      text-align: center;
    }

    td:nth-child(4) {
      // width: 50px !important;
      // text-align: center;
      padding: 10px 15px;
      text-align: center;
    }
  }
}

// tr.ant-table-row {
//   td {
//     max-width: 200px !important;
//   }
// }

li.ant-menu-submenu-selected {
  div:nth-child(1) {
    font-weight: bold;
  }
}

.ant-notification-notice-close {
  display: none !important;
}

.innertable-title-btn {
  width: max-content !important;
  margin: 5px 0;
}

// .ant-space-item {
//   width: 100% !important;
// }

// .tabletoggle-section {
//   // height: 100vh;
// }

.table-statu-fact {
  width: 60%;
}

.flow-expand {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.ant-table-pagination-right {
  align-items: center;
  justify-content: center;
  display: flex;
}

#loginform_wrapper {
  .createaccount-link {
    a {
      position: relative;
      top: -25px;
    }
  }
}

.register-user-pass {
  margin-bottom: 0px !important;
}

#recovery_request {
  .ant-form-item {
    margin-bottom: 0px;
  }

  .loginforgotlink {
    margin-bottom: 15px;
  }
}

.styles_login_wrapper__1TH8J .styles_login_form_logo__3Xt1I {
  width: 100%;
  text-align: center;
  margin-bottom: 5em;
  margin-left: -18px;
}

.styles_login_wrapper__1TH8J .styles_login_form_logo__3Xt1I img {
  width: 80px;
  margin: auto;
  text-align: center;
}

.ant-table-content {
  table {
    .ant-table-tbody {
      tr {
        &:hover td {
          background: #e6f7ff;
        }

        a {
          color: rgba(0, 0, 0, 0.85);
        }

        &:hover td {
          color: #1890ff !important;
        }

        &:hover a {
          color: #1890ff !important;
        }
      }
    }
  }
}

.ant-table-expanded-row {
  .ant-table {
    .ant-table-container {
      table {
        .ant-table-thead {
          display: none;
        }

        .ant-table-tbody {
          tr {
            background: #e6f7ff !important;

            td.ant-table-cell {
              float: left;
            }

            &:hover {
              background: #e6f7ff !important;
            }
          }
        }
      }
    }
  }
}

.ant-select-item-option-content {
  font-size: 12px;
}

.tableselect_toprow {
  .table-rows-select {
    font-size: 12px;
    color: #bfbfbf;
  }
}

.ant-upload-list-item-card-actions-btn {
  opacity: 1 !important;
}

.ant-upload-list-item-name {
  font-size: 11px;
  padding-top: 2px;
}

.tabletoggle-section {
  .table-statu-fact {
    .detail-table-confirmation {
      width: auto;

      tr {
        td {
          background: #fff;
        }
      }
    }
  }
}

.ant-input-affix-wrapper .ant-input::-webkit-input-placeholder {
  /* Edge */
  font-size: 14px;
}

.ant-input-affix-wrapper .ant-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-size: 14px;
}

.ant-input-affix-wrapper .ant-input::placeholder {
  font-size: 14px;
}

.searchiconalign {
  position: absolute;
  right: 10px;
  z-index: 9;
}

.onlyscreen_template_wrapper {
  .ant-form-item-label {
    label {
      &::after {
        content: "";
      }
    }
  }
}

.secretarymybills_wrapper {
  #mybillselect {
    margin: 0px 15px 0 0;
  }
}

#leftmenu-profileimg {
  img {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    margin-right: 10px;
    object-fit: cover;
  }
}

.profile_bailiff_wrapper {
  .validate-btn {
    margin-top: 1em;
  }

  .nouvellecalender-wrapper {
    text-align: right;
    width: 100%;
    margin-bottom: 2em;

    .nouvellecalender {
      //border: 1px solid;
      color: #40a9ff;
      //width: 189px;
      text-align: center;
      //height: 60px;
      //padding-top: 9px;
      background-color: #ffffff;
      border: 1px solid #40a9ff;
      //display: inline-block;
      //border-radius: 4px;
      //-webkit-border-radius: 4px;
      //-moz-border-radius: 4px;

      svg {
        margin-right: 5px;
      }
    }
  }

  .ant-picker-calendar-mode-switch {
    display: none;
  }

  .indisponibilité-link-wrapper {
    position: relative;
    width: 50%;
    top: 0px;

    .indisponibilité-link {
      font-size: 14px;

      &::before {
        float: left;
        content: " ";
        width: 14px;
        height: 14px;
        background: #ff1717;
        position: relative;
        top: 1px;
        margin-right: 10px;
      }
    }
  }
}

.ant-picker-calendar-full {
  .ant-picker-calendar-header {
    .ant-picker-calendar-mode-switch {
      .ant-radio-button-wrapper-checked {
        margin-right: 10px;
        z-index: -0;
      }
    }
  }
}

.ant-picker-calendar {
  .ant-picker-panel {
    .ant-picker-body {
      z-index: 0;
    }
  }
}

.noleftmenu_section {
  .viewnews-table {
    .ant-table-content {
      table {
        .ant-table-tbody {
          tr {
            td:first-child {
              text-align: center;
            }
          }
        }
      }
    }
  }
}

.bailiffdas-bord-mask {
  .dashboard-icon-center {
    height: 70px !important;
  }

  .bailiffcommlink {
    margin-top: 32px;
  }
}

.createreport_wrapper {
  h2 {
    margin-bottom: 0px;
  }

  .createreport_form {
    .ant-upload-list-text {
      .ant-upload-list-item-info {
        background: #e6f7ff;
        transition: 0.4s ease;

        &:hover {
          transition: 0.4s ease;
          background: #f5f5f5;
        }
      }
    }
  }
}

.bailiffprofile-form {
  .ant-upload-list-text {
    .ant-upload-list-item-info {
      background: #e6f7ff;
      transition: 0.4s ease;

      &:hover {
        transition: 0.4s ease;
        background: #f5f5f5;
      }
    }
  }
}

.mybillsgailif_wrapper {
  h2 {
    margin-bottom: 10px;
  }
}

.mybillsbailiff-table {
  margin-top: 1.5em;

  .tabledownloadreport {
    a {
      svg {
        fill: #1890ff;
      }
    }
  }
}

.ant-table {
  .ant-table-content {
    table {
      .ant-table-thead {
        tr {
          th {
            svg {
              font-size: 15px;
            }
          }
        }
      }

      .ant-table-tbody {
        tr {
          td {
            svg {
              font-size: 15px;
            }
          }
        }
      }
    }
  }
}

#yeardropdown {
  margin-left: 15px;
}

.anticon-plus {
  svg {
    font-size: 12px !important;
  }
}

.secaritertcal-mask {
  .ant-picker-calendar-mode-switch {
    display: block;
  }
}

.bailiffdashboard {
  .telecharger-link {
    svg {
      position: relative;
      top: 3px;
    }
  }
}

// .noleftmenu_section{
//   .bailiffdashboard{
//     width: 79% !important;
//     margin-top: 46px !important;
//     .ant-input-affix-wrapper-lg {
//       padding: 6.5px 30px 6.5px 11px;
//       font-size: 16px;
//       width: 90%;
//       float: right;
//   }
//   }
// }

// .creer-constat-wrapper.noleftmenu_section{
//   float: none;
//   width: 79%;
//   margin: 0 auto;
//   padding: 112px 30px;
// }

// .mesconstats-table{

// }

.form_outer_wrapper {
  .bailiffdashboard {
    float: none !important;
    margin: 0 auto !important;
    width: 85% !important;
    background: none !important;
  }
}

.dashboard-right-section {
  .createreportbaillif_wrapper {
    float: none !important;
    //    margin: 0 auto !important;
    width: 85% !important;
    background: none !important;
  }
}

.dashboard-right-section {
  .mybillsgailif_wrapper {
    float: right;
    width: 100%;
    padding: 80px 30px;
  }
}

.form_outer_wrapper {
  .billsgailif_wrapper {
    float: none !important;
    margin: 0 auto !important;
    width: 85% !important;
    background: none !important;
  }
}

// .form_outer_wrapper.billsgailif_wrapper{
//   margin-top: 47px !important;
//   padding: 0px 14px !important;
// }

.noleftmenu_section {
  .bailiffdas-bord-mask {
    .dashboard-icon-center {
      height: 58px !important;
    }
  }
}

.createreport_wrapper {
  .bailiffdas-bord-mask {
    .dashboard-icon-center {
      height: 58px !important;
    }
  }
}

.constatsencours-tables,
.createareportbailiff-tables,
.mesconstats-table {
  .ant-table-content {
    table {
      .ant-table-thead {
        tr {
          th:nth-child(5) {
            width: 25%;
          }

          th:nth-child(7) {
            text-align: center;
          }

          th:nth-child(8) {
            text-align: center;
          }
        }
      }

      .ant-table-tbody {
        tr {
          td:nth-child(5) {
            width: 25%;
          }

          td:nth-child(7) {
            text-align: center;
          }

          td:nth-child(8) {
            text-align: center;

            p {
              margin: 5px 0px;
            }
          }
        }
      }
    }
  }
}

.dashboard-right-section.seetheaccount_wrapper_mask {
  .dashboard-right-section {
    margin-top: -100px;
  }
}

.bailiff-notification {
  .ant-tabs-nav-list {
    .ant-tabs-tab-active {
      width: 100%;
    }
  }
}

// tr.ant-table-expanded-row{
//   &:hover{
//     background: #f00;
//   }
// }
.pagetitle-table {
  text-transform: uppercase;
  margin-top: 0.5em;
  margin-bottom: 0.5em !important;
}

tr.ant-table-expanded-row {
  background: #fbfbfb !important;

  .ant-table {
    .ant-table-container {
      table {
        .ant-table-tbody {
          tr {
            background: white !important;

            &:hover {
              background: white !important;
            }

            &:hover td {
              color: #595959 !important;
            }
          }

          td {
            background: white !important;

            &:hover {
              background: white !important;
            }
          }
        }
      }
    }
  }

  &:hover td,
  &:hover li,
  &:hover a {
    color: #595959 !important;
  }

  &:hover .title-one,
  &:hover .first-title,
  &:hover .title-two,
  &:hover .detail-table-confirmation {
    color: #595959 !important;
  }
}

tr.ant-table-expanded-row>td,
tr.ant-table-expanded-row:hover>td {
  background: #fbfbfb !important;
}

.createareportbailiff-tables {
  table {
    .ant-table-thead {
      tr {
        th:last-child {
          text-align: center;
        }
      }
    }

    .ant-table-tbody {
      tr {
        td:nth-child(2) {
          text-align: center;
        }

        td:last-child {
          text-align: center;
        }
      }
    }
  }
}

.mesconstats-table {
  table {
    .ant-table-thead {
      tr {
        th:last-child {
          text-align: center;
        }
      }
    }

    .ant-table-tbody {
      tr {
        td:nth-child(2) {
          text-align: center;
        }

        td:last-child {
          text-align: center;
        }
      }
    }
  }
}

.successalertemail {
  width: 400px;

  .ant-alert-message {
    color: #595959;
  }
}

.successalertemaillink-wrapper {
  width: 400px;
  text-align: center;

  .successalertemaillink {
    float: left;
    width: 100%;
    padding: 20px 15px 0px 15px;
    text-align: center;
    margin-top: 1em;
    font-size: 14px;
    font-weight: 400;
    color: #1890ff;
    text-decoration: underline;
  }
}

.formtoptitles-wrappers {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #979797;
  margin-bottom: 1.5rem;
}

// .ant-table-pagination-right{
//   float:right;
// }

#password_request {
  .site-form-item-icon {
    svg {
      path {
        color: #1890ff;
        fill: #1890ff;
      }
    }
  }
}

.seetheaccount_wrapper_mask {
  h2.commonpagetitle_leftmenu {
    margin-bottom: 0px;
  }
}

.manageiconforalign {
  table {
    .ant-table-thead {
      tr {
        th:last-child {
          text-align: left;
        }
      }
    }

    .ant-table-tbody {
      tr {
        td {
          text-align: center;

          div.ant-space-item:last-child .anticon-folder-view svg {
            font-size: 18px;
            position: relative;
          }
        }
      }
    }
  }
}

table p {
  margin-bottom: 0px;
}

.ant-spin-nested-loading {
  .tabletoggle-section {
    .table-audio-icons {
      ul {
        li {
          span {
            svg {
              font-size: 26px !important;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}

.ant-spin-nested-loading {
  .tabletoggle-section {
    .table-audio-icons {
      ul {
        li {
          a {
            span {
              svg {
                font-size: 26px !important;
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
  }
}

.secretarymybills_wrapper {
  .billssecretaries-table {
    table {
      .ant-table-tbody {
        tr {
          td:last-child {
            color: #1890ff;
          }
        }
      }
    }
  }
}

.bailiffdashboard {
  table {
    .ant-table-thead {
      tr {
        th:nth-child(3) {
          text-align: left;
          width: 20%;
        }
      }

      tr {
        th:nth-child(5) {
          text-align: left;
          width: 14% !important;
        }
      }
    }

    .ant-table-tbody {
      tr {
        td:nth-child(3) {
          text-align: left;
          width: 20%;
        }

        td:nth-child(5) {
          text-align: left;
          width: 14% !important;
        }
      }
    }
  }
}

.constatsencours_wrapper {
  .constatsencours-tables {
    table {
      .ant-table-tbody {
        tr {
          td:nth-child(6) {
            .ant-space.ant-space-horizontal {
              div:nth-child(2) {
                cursor: pointer;
                margin-right: 12px !important;
              }

              div:nth-child(3) {
                margin-right: 0px !important;
              }
            }
          }
        }
      }
    }
  }
}

.facturestable-wrapper {
  table {
    .ant-table-thead {
      tr {
        th:first-child {
          text-align: center !important;
          width: 16.6% !important;
        }

        th:nth-child(2) {
          text-align: center !important;
          width: 16.6% !important;
        }

        th:nth-child(3) {
          text-align: center !important;
          width: 16.6% !important;
        }

        th:nth-child(4) {
          text-align: center !important;
          width: 16.6% !important;
        }

        th:nth-child(5) {
          text-align: center !important;
          width: 16.6% !important;
        }

        th:last-child {
          text-align: center !important;
          width: 16.6% !important;
        }
      }
    }

    .ant-table-tbody {
      tr {
        td:first-child {
          text-align: center !important;
          width: 16.6% !important;
        }

        td:nth-child(2) {
          text-align: center !important;
          width: 16.6% !important;
        }

        td:nth-child(3) {
          text-align: center !important;
          width: 16.6% !important;
        }

        td:nth-child(4) {
          text-align: center !important;
          width: 16.6% !important;
        }

        td:nth-child(5) {
          text-align: center !important;
          width: 16.6% !important;
        }

        td:last-child {
          text-align: center !important;
          width: 16.6% !important;
        }
      }
    }
  }
}

.profile-website {
  display: block;
}

.profile-mobile {
  display: none;
}

.responsivecarousel-wrapper {
  display: none;
}

.profileimgresponsive {
  display: none;
}

.profileimgweb {
  display: block;
}

.bailiffdashboard {
  .mesconstats-table {
    table {
      .ant-table-thead {
        tr {
          th:nth-child(2) {}

          th:nth-child(8) {
            text-align: left;
          }

          th:last-child {
            text-align: left;
          }
        }
      }

      .ant-table-tbody {
        tr {
          td:nth-child(2) {
            text-align: left;
          }

          td:nth-child(7) {
            text-align: center;
          }

          td:nth-child(8) {
            text-align: left;
          }

          td:last-child {
            text-align: left;
          }
        }
      }
    }
  }
}

.facturestable-wrapper {
  table {
    .ant-table-thead {
      tr {
        th:nth-child(2) {
          text-align: center !important;
        }

        th:nth-child(3) {
          text-align: center !important;
        }

        th:nth-child(4) {
          text-align: center !important;
        }

        th:nth-child(5) {
          text-align: center !important;
        }

        th:nth-child(6) {
          text-align: center !important;
        }

        th:nth-child(7) {
          text-align: center !important;
        }
      }
    }

    .ant-table-tbody {
      tr {
        td:nth-child(2) {
          text-align: center !important;
        }

        td:nth-child(3) {
          text-align: center !important;
        }

        td:nth-child(4) {
          text-align: center !important;
        }

        td:nth-child(5) {
          text-align: center !important;
        }

        td:nth-child(6) {
          text-align: center !important;
        }

        td:nth-child(7) {
          text-align: center !important;
        }
      }
    }
  }
}

.retrocessionsendbill_table {
  table {
    .ant-table-tbody {
      tr {
        td:nth-child(5) {
          .ant-space-align-center {
            .ant-space-item {
              margin-right: 30px !important;
            }
          }
        }

        td:nth-child(9) {
          text-align: center;
        }

        td:nth-child(4) {
          left: 17px;
        }

        td:nth-child(6) {
          left: 18px;
        }

        td:nth-child(7) {
          left: 19px;
        }

        td:nth-child(8) {
          left: 18px;
        }
      }
    }
  }
}

@import "responsive.scss";
