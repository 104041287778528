.form-create {
  width: 70%;
  margin: auto;

  .ant-row {
    .ant-col {
      margin: auto;
    }
  }
}

.item-inline-form {
  .ant-form-item-row {
    display: block;

    .ant-form-item-label {
      text-align: left;
    }
  }
}

.remove-margin-bottom {
  margin-bottom: 0 !important;
}

.clickable {
  cursor: pointer;
}

.disabled-upload-dragger {
  display: none;
}

.auto-adjust-text {
  .ant-form-item-label {
    white-space: pre-wrap;
    overflow-wrap: break-word;
  }
}

.adapt-file-list-bailiff-profile {
  .ant-upload-list {
    width: 80% !important;
  }
}

.red-dot-alert {
  width: 5px;
  height: 5px;
  position: absolute;
  background-color: red;
  top: 7px;
  border-radius: 3px;
}

.green-dot-alert {
  width: 5px;
  height: 5px;
  position: absolute;
  background-color: green;
  top: 7px;
  border-radius: 3px;
}

.red-dot-container {
  position: relative;
  width: calc(100% + 15px);
  display: table;
}

.red-border {
  border: 1px solid red;
}

.green-border {
  border: 1px solid green;
}

.title-expanded-subsection {
  font-size: large;
  font-weight: 500;
}

.ant-modal-close-icon {
  justify-content: center;
}

.ant-tabs-tabpane {
  height: inherit;
}

.ant-tabs-content {
  height: inherit;
}

.ant-select-single .ant-select-selector {
  align-items: center;
}
