/*responsive layout*/
@media (min-width: 1601px) and (max-width: 1680px) {}

@media (min-width: 1441px) and (max-width: 1600px) {
  .bailiffdashboard {
    width: 100% !important;
    padding: 0px !important;
  }

  .userprofile-layout-wrapper {
    width: 100%;
  }
}

@media (min-width: 300px) and (max-width: 1280px) {
  .constatsencours-tables {
    position: static;
    top: 0;
    overflow-x: scroll;
    background-color: white;
  }

  .ant-table-content {
    table {
      overflow-x: scroll;
    }
  }
}

@media (min-width: 1440px) and (max-width: 3480px) {
  body {
    overflow-x: hidden;
  }

  .ant-layout {
    //width: 100vw;
    max-width: 3480px !important;
  }

  .ant-layout-content {
    margin-left: 0px
  }

  .dashboard-right-section {
    margin-left: 0px;
  }

  .chart-top-select-wrapper {
    margin-left: 4em
  }

  .statistiques_wrapper {
    margin-left: 4em;
  }
}

@media (min-width: 1367px) and (max-width: 1440px) {
  .ant-layout-content {
    margin-left: 0px
  }

  .dashboard-right-section {
    margin-left: 0px;
  }

  .chart-top-select-wrapper {
    margin-left: 4em
  }

  .statistiques_wrapper {
    margin-left: 4em;
  }

  .dashboard-right-section {
    width: 98%;

    &.dashboard-bailiff,
    &.dashboard-secretary {
      width: 100%;
    }
  }

  .bailiffdashboard {
    width: 100% !important;
    padding: 0px !important;
  }

  // .constatsencours-table {
  //   .ant-table-content {
  //     table {
  //       .ant-table-tbody {
  //         tr {
  //           td:first-child {
  //             min-width: 0%;
  //           }

  //           td:nth-child(2) {
  //             min-width: 10%;
  //           }

  //           td:nth-child(3) {
  //             min-width: 10%;
  //           }

  //           td:nth-child(4) {
  //             min-width: 10%
  //           }

  //           td:nth-child(5) {
  //             min-width: 20%
  //           }

  //           td:nth-child(6) {
  //             min-width: 20%
  //           }

  //           td:nth-child(7) {
  //             min-width: 10%;
  //           }

  //           td:nth-child(8) {
  //             min-width: 10%;
  //           }

  //           td:last-child {
  //             min-width: 10%;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
  .constatsencours_wrapper {
    width: 98%;

    .ant-table-content {
      table {
        .ant-table-thead {
          tr {
            th {
              padding: 16px 10px;
              width: auto !important;
              min-width: auto !important;
            }
          }

          tr {
            th:nth-child(5) {
              width: 25% !important;
            }

            th:nth-child(7) {
              width: 20% !important;
            }
          }
        }

        .ant-table-tbody {
          tr {
            td {
              padding: 16px 10px;
              width: auto !important;
              min-width: auto !important;
            }
          }

          tr {
            td:nth-child(5) {
              width: 25% !important;
            }

            td:nth-child(7) {
              width: 20% !important;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1281px) and (max-width: 1366px) {
  // .ant-table table {
  //   overflow-x: auto !important;
  //   display: inline-block !important;
  // }

  // .creerunconstat-wrapper{
  .dashboard-right-section {
    float: right;
    width: 98%;
    // width: 100% !important;
    padding: 80px 30px;
  }

  // .bailiffdashboard{
  //   width: 100% !important;
  //   padding: 0px !important;
  // }
  // }
  .dashboard-right-section {
    .create_account {
      .ant-form-item-control-input-content {
        .ck.ck-editor {
          width: 290px;
        }
      }
    }
  }

  // .ant-table-content {
  //   table {
  //     .ant-table-tbody {
  //       tr {
  //         td:nth-child(3) {
  //           min-width: 170px;
  //         }

  //         td:nth-child(4) {
  //           min-width: 120px;
  //         }

  //         td:nth-child(5) {
  //           min-width: 330px;
  //         }

  //         td:nth-child(6) {
  //           min-width: 160px;
  //         }

  //         td:nth-child(7) {
  //           min-width: 230px;
  //         }
  //       }
  //     }
  //   }
  // }

  // .mesconstats-table {
  //   .ant-table-content {
  //     table {
  //       .ant-table-tbody {
  //         tr {
  //           td:nth-child(1) {
  //             min-width: 60px;
  //           }

  //           td:nth-child(2) {
  //             min-width: 150px;
  //           }

  //           td:nth-child(3) {
  //             min-width: 330px;
  //           }

  //           td:nth-child(4) {
  //             min-width: 200px;
  //           }

  //           td:nth-child(5) {
  //             min-width: 200px;
  //           }

  //           td:nth-child(6) {
  //             min-width: 75px;
  //           }

  //           td:nth-child(7) {
  //             min-width: 75px;
  //           }

  //           td:nth-child(8) {
  //             min-width: 85px;
  //           }

  //           td:nth-child(9) {
  //             min-width: 100px;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
  .ant-form-item-label>label {
    font-size: 11px;
  }

  .ant-upload.ant-upload-drag p.ant-upload-hint {
    font-size: 8px;
  }

  .createreport_wrapper {
    width: 100% !important;
  }

  .constatsencours_wrapper {
    width: 98%;

    .ant-table-content {
      table {
        .ant-table-thead {
          tr {

            // th{
            //   width: auto !important;
            //   min-width: auto !important;
            // }
            th:first-child {
              font-size: 13px;
            }

            th:nth-child(2) {
              font-size: 13px;
            }

            th:nth-child(3) {
              font-size: 13px;
            }

            th:nth-child(4) {
              font-size: 13px;
            }

            th:nth-child(5) {
              font-size: 13px;
            }

            th:nth-child(6) {
              font-size: 13px;
            }

            th:nth-child(7) {
              font-size: 13px;
            }

            th:nth-child(8) {
              font-size: 13px;
            }

            th:last-child {
              font-size: 13px;
            }
          }

          // tr {
          //   th:nth-child(5){
          //     width: 25% !important;
          //   }
          //   th:nth-child(7){
          //     width: 20% !important;
          //   }
          // }
        }

        .ant-table-tbody {
          tr {
            td:first-child {
              font-size: 13px;
            }

            td:nth-child(2) {
              font-size: 13px;
            }

            td:nth-child(3) {
              font-size: 13px;
            }

            td:nth-child(4) {
              font-size: 13px;
            }

            td:nth-child(5) {
              font-size: 13px;
            }

            td:nth-child(6) {
              font-size: 13px;
            }

            td:nth-child(7) {
              font-size: 13px;
            }

            td:nth-child(8) {
              font-size: 13px;
            }

            td:last-child {
              font-size: 13px;
            }

            td {
              .ant-select-selection-item {
                font-size: 13px;
              }
            }
          }

          // tr{
          //   td:nth-child(5){
          //     width: 25% !important;
          //   }
          //   td:nth-child(7){
          //     width: 20% !important;
          //   }
          // }
        }
      }
    }
  }

  .constatsencours_wrapper {
    width: 98%;

    .ant-table-content {
      table {
        .ant-table-thead {
          th:first-child {
            font-size: 13px;
          }

          th:nth-child(2) {
            font-size: 13px;
          }

          th:nth-child(3) {
            font-size: 13px;
          }

          th:nth-child(4) {
            font-size: 13px;
          }

          th:nth-child(5) {
            font-size: 13px;
          }

          th:nth-child(6) {
            font-size: 13px;
          }

          th:nth-child(7) {
            font-size: 13px;
          }

          th:nth-child(8) {
            font-size: 13px;
          }

          th:last-child {
            font-size: 13px;
          }
        }
      }

      .ant-table-tbody {
        tr {
          td:first-child {
            font-size: 13px;
          }

          td:nth-child(2) {
            font-size: 13px;
          }

          td:nth-child(3) {
            font-size: 13px;
          }

          td:nth-child(4) {
            font-size: 13px;
          }

          td:nth-child(5) {
            font-size: 13px;
          }

          td:nth-child(6) {
            font-size: 13px;
          }

          td:nth-child(7) {
            font-size: 13px;
          }

          td:nth-child(8) {
            font-size: 13px;
          }

          td:last-child {
            font-size: 13px;
          }

          td {
            .ant-select-selection-item {
              font-size: 13px;
            }
          }
        }
      }
    }
  }


  .tabletop-filter-section {
    .tabletop-filter-options {
      font-size: 13px;
      margin-top: 2px;
    }

    .table-search-input {
      .ant-input {
        font-size: 13px;
      }
    }
  }

  // .ant-table-content {
  //   table {
  //     .ant-table-thead {
  //       tr {
  //         th{
  //           width: auto !important;
  //           min-width: auto !important;
  //           max-width: auto !important;
  //         }
  //       }
  //     }
  //     .ant-table-tbody {
  //       tr {
  //         td {
  //           width: auto !important;
  //           min-width: auto !important;
  //           max-width: auto !important;
  //         }
  //       }
  //     }
  //   }
  // }
  .ant-upload-select-picture-card {
    .ant-upload {
      a {
        font-size: 12px !important;

        .anticon-plus {
          svg {
            width: 15px !important;
            height: 12px !important;
          }
        }
      }

    }
  }

  .tabletoggle-section-list-mask {
    .table-statu-fact {

      // .ant-row{
      //   margin: 0px -15px;
      // }
      h2 {
        font-size: 16px !important;
      }

      .table-vin-pag {
        padding: 0px !important;

        .table-statu-fact-info {
          padding: 0px 15px;

          .title {
            span {
              font-size: 11px !important;
              padding-left: 5px !important;
            }
          }

          .ant-checkbox-wrapper {
            span {
              font-size: 11px !important;
              padding-left: 5px !important;
            }
          }
        }

        .first-title {
          position: relative;
          top: 6px;
        }
      }

      .envoyer-btn {
        margin-top: 80px !important;
        padding: 0px !important;
        min-width: 60px !important;
        font-size: 12px !important;
        height: 25px !important;
      }

      .table-statu-fact-info-title {
        .info-title {
          .title-one {
            margin-top: -2px;
          }

          .title-two {
            margin-top: 20px;
          }
        }
      }
    }
  }

  .tableselect_toprow {
    .table-rows-select {
      font-size: 12px;
      color: #bfbfbf;
    }
  }

  .ant-table-expanded-row {
    .ant-table {
      .ant-table-container {
        table {
          .ant-table-tbody {
            tr {
              td.ant-table-cell {
                .tabletoggle-section {
                  .tabletoggle-section-title {
                    .ant-space-item {
                      &:last-child {
                        float: left;
                        width: 60%;
                      }

                      h2 {
                        font-size: 15px;
                      }
                    }
                  }

                  .tabletoggle-section-list-mask {
                    .tabletoggle-section-list {
                      .tabletoggle-section-info {
                        h2 {
                          font-size: 12px;
                        }
                      }

                      .table-autio-date {
                        ul {
                          li {
                            font-size: 10px;
                          }
                        }
                      }
                    }

                    .table-statu-fact {
                      .detail-table-confirmation {
                        width: auto;
                        float: left;

                        tr {
                          background: #fff !important;

                          &:hover td {
                            background: #fff !important;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .ant-table-content {
    table {
      .ant-table-thead {
        tr {
          th {
            font-size: 13px;
            padding: 15px 5px;
          }

          th:first-child {
            font-size: 13px;
            padding: 15px 5px 15px 15px;
          }
        }
      }

      .ant-table-tbody {
        tr {
          td {
            font-size: 13px;
            padding: 15px 5px;
          }

          td:first-child {
            font-size: 13px;
            padding: 15px 5px 15px 15px;
          }
        }
      }
    }
  }

  .retrocessionadminreports_wrapper .form_tableouter_wrapper .table-toptable-layout .left-section .left-section-content .left-section-content-left {
    font-size: 12px;
  }

  .facturation-table {
    .ant-table-content {
      table {
        .ant-table-tbody {
          tr {
            td {
              svg {
                height: 15px !important;
                width: 15px !important;
              }
            }
          }
        }
      }
    }
  }

  .retrocessionadminreports-modal {
    .ant-modal-body {
      h2.modal_title {
        font-size: 15px;
      }

      .ant-comment-content-detail {
        p {
          font-size: 11px;
        }
      }

      .ant-comment-content-author {
        .ant-comment-content-author-name {
          .comment_avt_name {
            font-size: 12px;
          }

          .comment_avt_date {
            font-size: 11px;
          }
        }
      }

      .ant-select-selection-item {
        font-size: 11px;
      }

      .reply-btn_wrapper {
        .reply-btn {
          span {
            font-size: 11px;
          }
        }
      }
    }
  }

  .ant-select-selector {
    .ant-select-selection-item {
      font-size: 12px;
    }
  }

  .ant-input-affix-wrapper .ant-input::-webkit-input-placeholder,
  .ant-form-item-control-input-content .ant-input::-webkit-input-placeholder {
    /* Edge */
    font-size: 11px;
  }

  .ant-input-affix-wrapper .ant-input:-ms-input-placeholder,
  .ant-form-item-control-input-content .ant-input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-size: 11px;
  }

  .ant-input-affix-wrapper .ant-input::placeholder,
  .ant-form-item-control-input-content .ant-input::placeholder {
    font-size: 11px;
  }

  .ant-input-affix-wrapper .ant-input,
  .ant-form-item-control-input-content .ant-input {
    /* Edge */
    font-size: 11px;
  }

  .ant-input-affix-wrapper .ant-input,
  .ant-form-item-control-input-content .ant-input {
    /* Internet Explorer 10-11 */
    font-size: 11px;
  }

  .ant-input-affix-wrapper .ant-input,
  .ant-form-item-control-input-content .ant-input {
    font-size: 11px;
  }

  .table-search-input {
    padding: 2px 30px 2px 4px;
    height: 29px;
  }

  .viewnews_wrapper {
    .form_viewnews_wrapper {
      .tabletop-filter-section {
        .table-search-input {
          width: 170px;
          height: unset;
        }
      }

      .ant-select-show-arrow {
        .ant-select-selector {
          height: unset !important;
          padding: 0px 11px !important;
        }
      }
    }
  }

  .profile_section {
    .adminprofile-form {
      label {
        font-size: 12px;
      }
    }
  }

  .ant-checkbox+span {
    font-size: 12px;
  }

  .createanews-wrapper {
    .form_outer_onlyscreenwrapper {
      .form_onlyscreenwrapper {
        .create_news {
          .createnews-editor {
            .ck-content {
              height: 200px;
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  .ant-menu-sub.ant-menu-inline>.ant-menu-item,
  .ant-menu-sub.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title {
    font-size: 12px;
  }

  #loginform_wrapper {
    .ant-form-item {
      #normal_login_email {
        img {
          top: 9px;
        }
      }

      #normal_login_password {
        img {
          top: 6px;
        }
      }
    }
  }

  .secretarydashboard {
    .form_tableouter_wrapper {
      h2 {
        margin-bottom: 1em;
      }

      .tabletop_addbtn {
        min-width: 160px;
        height: 30px;
        padding: 4px;
        font-size: 12px;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
      }

      .flow-expand {
        .tabletop-filter-options {
          font-size: 12px;
          margin-top: 2px;
        }
      }
    }
  }

  .retrocessionsendbill_wrapper {
    .form_tableouter_wrapper {
      .table-toptable-layout {
        width: 300px;

        .left-section {
          .left-section-content {
            .left-section-content-left {
              font-size: 12px;
            }
          }

          .left-section-top-title {
            .top-title-left-section {
              font-size: 13px;
            }

            .top-title-right-section {
              font-size: 13px;
            }
          }
        }

        .right-section {
          .left-section-content-total {
            font-size: 13px !important;
          }

          .top-title-right-section {
            font-size: 13px;
          }
        }
      }
    }
  }


  .noleftmenu_section {
    .secretarydashboard {
      h2 {
        margin-bottom: 1em;
      }
    }
  }

  .secretaryprofile-form {
    .validate-btn {
      min-width: 70px;
      height: 30px;
      font-size: 12px;
      margin-top: 1em;
    }
  }

  .userprofile-layout-wrapper {
    .content-section-wrapper {
      .ant-picker-calendar-full {
        .ant-picker-calendar-header {
          .ant-picker-calendar-mode-switch {
            .ant-radio-button-wrapper-checked {
              font-size: 11px;
              margin-right: 10px;
            }

            .ant-radio-button-wrapper {
              span {
                font-size: 11px;
              }
            }
          }

        }

        .ant-picker-panel {
          .ant-picker-date-panel {
            .ant-picker-body {
              table.ant-picker-content {
                thead {
                  tr {
                    th {
                      font-size: 11px;
                    }
                  }
                }

                tbody {
                  tr {
                    td {
                      .ant-picker-calendar-date {
                        .ant-picker-calendar-date-value {
                          font-size: 11px;
                        }

                        .ant-picker-calendar-date-content {
                          height: 35px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .validate-btn {
      min-width: 70px;
      height: 30px;
      font-size: 12px;
      margin-top: 1em;
    }
  }

  .viewsecretarie-modal {
    .ant-modal-body {
      .modalflaticon-empty {
        top: 21px;
      }

      .modalflaticon {
        top: 21px;
      }

      .modal-container {
        h2 {
          font-size: 14px;
          font-weight: bold;
          line-height: 16px;
        }

        .viewsecretarie-date {
          font-size: 12px;
        }

        div {
          p {
            font-size: 11px;
          }
        }
      }
    }
  }

  .bailiffdashboard {
    .nouvelle-btn {
      .anticon-plus {
        svg {
          font-size: 12px !important;
        }

      }
    }
  }

  .bailiffdashboard {
    .nouvelle-btn {
      height: unset;
      font-size: 12px;
      float: right;
      margin-right: 7px;
    }
  }

  .profile_bailiff_wrapper {
    .bailiffprofile-form {
      label {
        font-size: 12px;
      }
    }
  }

  .profile_bailiff_wrapper {
    .cancel-btn {
      min-width: 70px;
      height: 30px;
      font-size: 12px;
    }
  }

  .profile_bailiff_wrapper {
    .nouvellecalender-wrapper {
      .nouvellecalender {
        text-align: center;
        height: 30px;
        font-size: 12px;
        padding-top: 6px;
      }
    }
  }

  .commontopfourwrapper {
    h2 {
      a {
        font-size: 15px;
      }
    }
  }

  .form_outer_wrapper.bailiffdashboard {
    h2 {
      margin-bottom: 1em;
      font-size: 15px;
    }

    .tabletop-filter-options {
      font-size: 12px;
      text-align: right;
      margin-top: 2px;
    }
  }

  .form_outer_wrapper {
    .toptitlewithunderline {
      font-size: 15px;
    }
  }

  .register-btn-mask {
    margin: 0em 2em 4em 0 !important;

    .validate-btn {
      height: auto;
      min-width: auto;
      font-size: 12px;
    }

    .cancel-btn {
      height: auto;
      min-width: auto;
      font-size: 12px;
    }
  }

  .tabletoggle-section-title {
    .ant-space-item {
      a {
        font-size: 12px !important;
        padding: 2px 8px 4px !important;
      }
    }
  }

  .retrocessionsendbill_wrapper {
    .factures-wrapper {
      .toptitlesection {
        .toptitleleft {
          font-size: 15px;
        }

        .toptitleright {
          a {
            font-size: 12px;
          }
        }
      }
    }
  }

  .createreportbaillif_wrapper,
  .billsgailif_wrapper {
    h2 {
      font-size: 15px;
    }
  }

  .secretarydashboard {
    h2 {
      font-size: 15px;
    }
  }

  .mybillsgailif_wrapper .form_outer_wrapper {
    float: left;
    width: 100% !important;
    padding: 32px 14px !important;
  }

  .constatsencours_wrapper {
    h2 {
      font-size: 15px;
    }
  }

  .creerunconstat-wrapper {
    h2 {
      font-size: 15px;
    }
  }

  .creerunconstat-wrapper h2.pagewrapper_top {
    font-size: 15px;
    margin: 1em 0 0.5em 0;
  }

  .retrocessionadminreports_wrapper .form_tableouter_wrapper h2 {
    margin: 1em 0;
    font-size: 15px;
  }

  .seetheaccount_wrapper_mask {
    h2 {
      font-size: 15px;
    }
  }

  .bailiffaccount_wrapper {
    h2 {
      font-size: 15px;
    }
  }

  .voirlesgroupes_wrapper_mask {
    h2.innerpages_title {
      font-size: 15px;
      margin: 1em 0;
    }
  }

  .dashboard-right-section {
    .pagewrapper_top {
      font-size: 15px;
      margin: 1em 0;
    }
  }

  .dashboard-right-section {
    h2 {
      font-size: 15px;
    }
  }

  .viewnews_wrapper {
    h2 {
      font-size: 15px !important;
      margin: 1em 0 !important;
    }
  }

  .userprofile-layout-wrapper .ant-layout.ant-layout-has-sider .ant-layout .ant-layout-header h2 {
    font-size: 15px;
  }

  .ajouter-un-fichier-btn svg {
    width: auto !important;
    height: auto !important;
  }
}

@media (min-width: 1024px) and (max-width: 1280px) {
  // .ant-table table {
  //   overflow-x: auto !important;
  //   display: inline-block !important;
  // }

  // .constats-table .ant-table-content .ant-table-tbody tr td:nth-child(5) {
  //   min-width: 150px;
  // }

  // .constats-table .ant-table-content .ant-table-tbody tr td:nth-child(6) {
  //   min-width: 200px;
  // }

  // .constats-table .ant-table-content .ant-table-tbody tr td:nth-child(3) {
  //   min-width: 120px;
  // }

  // .constats-table .ant-table-content .ant-table-tbody tr td:nth-child(4) {
  //   min-width: 300px;
  // }

  .dashboard-right-section {
    float: right;
    width: 100% !important;
    padding: 80px 30px;

    .form_outer_wrapper {
      width: 100% !important;
      padding: 0px;
    }
  }

  // .ant-table-content{
  //   table{
  //     .ant-table-tbody{
  //       tr{
  //         td:nth-child(3){
  //           min-width: 170px;
  //         }
  //         td:nth-child(4){
  //           min-width: 120px;
  //         }
  //         td:nth-child(5){
  //          min-width: 330px;
  //         }
  //         td:nth-child(6){
  //           min-width: 160px;
  //         }
  //         td:nth-child(7){
  //           min-width: 230px;
  //         }
  //       }
  //     }
  //   }
  // }
  .noleftmenu_section {
    .dashboard-right-section {
      .form_outer_wrapper {
        padding: 0px;
        width: 100%;
      }
    }
  }

  .ant-upload.ant-upload-drag p.ant-upload-hint {
    font-size: 8px;
  }

  .creerunconstat-wrapper {
    .form_onlyscreenwrapper {
      width: 50% !important;
    }
  }

  .creerunconstat-wrapper {
    .dashboard-right-section {
      float: right;
      width: 92% !important;
      padding: 80px 30px;
    }
  }

  .dashboard-right-section {
    .create_account {
      .register-btn-mask {
        .validate-btn {
          min-width: 80px;
          margin: 0px 8px 0px 0px;
        }

        .cancel-btn {
          min-width: 80px;
          margin: 0px;
        }
      }
    }
  }

  // .seetheaccount-table{
  //   .ant-table-content{
  //     table{
  //       .ant-table-tbody{
  //         tr{
  //           td:nth-child(2){
  //             min-width: 160px;
  //           }
  //           td:nth-child(3){
  //             min-width: 160px;
  //           }
  //           td:nth-child(4){
  //             min-width: 400px;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
  .dashboard-right-section {
    .create_account {
      .ant-form-item-control-input-content {
        .ck.ck-editor {
          width: 180px;
        }
      }
    }
  }

  .dashboard-right-section {
    .form_outer_onlyscreenwrapper {
      .form_onlyscreenwrapper {
        .create_account {
          .ant-form-item {
            .ant-form-item-label {
              width: 41%;
            }
          }
        }
      }
    }
  }

  // .mesconstats-table {
  //   .ant-table-content {
  //     table {
  //       .ant-table-tbody {
  //         tr {
  //           td:nth-child(1) {
  //             min-width: 60px;
  //           }

  //           td:nth-child(2) {
  //             min-width: 150px;
  //           }

  //           td:nth-child(3) {
  //             min-width: 330px;
  //           }

  //           td:nth-child(4) {
  //             min-width: 200px;
  //           }

  //           td:nth-child(5) {
  //             min-width: 200px;
  //           }

  //           td:nth-child(6) {
  //             min-width: 75px;
  //           }

  //           td:nth-child(7) {
  //             min-width: 75px;
  //           }

  //           td:nth-child(8) {
  //             min-width: 85px;
  //           }

  //           td:nth-child(9) {
  //             min-width: 100px;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }

  .seetheaccount-table {
    .ant-table-content {
      table {
        .ant-table-thead {
          tr {
            th:first-child {
              width: 5%;

            }

            th:nth-child(2) {
              width: 30%;

            }

            th:nth-child(3) {
              width: 25%;

            }

            th:nth-child(4) {
              width: 30%;

            }

            th:last-child {
              width: 10%;

            }
          }
        }

        .ant-table-tbody {
          tr {
            td:first-child {
              width: 5%;

            }

            td:nth-child(2) {
              width: 30%;

            }

            td:nth-child(3) {
              width: 25%;

            }

            td:nth-child(4) {
              width: 30%;

            }

            td:last-child {
              width: 10%;

            }
          }
        }
      }
    }
  }

  .ant-form-item-label>label {
    font-size: 12px;
  }

  .dashboard-right-section {
    .bailiffaccount_form {
      .ant-form-item-control {
        .ant-input {
          &::placeholder {
            font-size: 10px;
          }
        }
      }


    }

    .create_account {
      .profile_uploadimg_wrapper {
        .groupe-button {
          .validate-btn {
            margin-top: 32px;
          }
        }
      }
    }
  }

  .bailiffdashboard {

    .nouvelle-btn {
      font-size: 10px;
    }

    .tabletop-filter-options {
      font-size: 12px;
    }
  }

  .mesconstats-table {
    .telecharger-link {
      font-size: 9px !important;
    }
  }

  .dashboard-right-section {
    float: right;
    width: 93% !important;
    padding: 80px 30px;

    &.dashboard-bailiff {
      width: 100% !important;

      .tabletop-filter-options {
        text-align: left;
      }

      .MuiGrid-root.MuiGrid-grid-lg-12 {
        width: 100%;

        .ant-row {
          justify-content: normal;
          gap: 10px;

          .ant-col-lg-3 {
            max-width: 100%;

            .table-rows-select {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .chart-top-select-wrapper {
    .chart-top-select {
      .ant-select {
        width: auto;
      }
    }
  }

}

@media (min-width: 768px) and (max-width: 1023px) {
  // .ant-table table {
  //   overflow-x: auto !important;
  //   display: inline-block !important;
  // }

  .constats-table .ant-table-content .ant-table-tbody tr td:nth-child(5) {
    min-width: 150px;
  }

  .constats-table .ant-table-content .ant-table-tbody tr td:nth-child(6) {
    min-width: 200px;
  }

  .constats-table .ant-table-content .ant-table-tbody tr td:nth-child(3) {
    min-width: 120px;
  }

  .constats-table .ant-table-content .ant-table-tbody tr td:nth-child(4) {
    min-width: 300px;
  }

  .dashboard-icon-center {
    display: flex;
    align-items: flex-end !important;
    justify-content: center;
    height: 75px !important;
  }

  .tabletoggle-section-list {
    .table-audio-icons {
      float: right;
      width: 10%;

      ul {
        float: left;
        width: 100%;
        padding: 0px;
        margin: 0px;
        list-style: none;

        li {
          float: left;

          svg {
            width: 20px;
          }
        }
      }
    }
  }

  // .ant-table-content {
  //   table {
  //     .ant-table-tbody {
  //       tr {
  //         td:nth-child(3) {
  //           min-width: 170px;
  //         }

  //         td:nth-child(4) {
  //           min-width: 120px;
  //         }

  //         td:nth-child(5) {
  //           min-width: 330px;
  //         }

  //         td:nth-child(6) {
  //           min-width: 160px;
  //         }

  //         td:nth-child(7) {
  //           min-width: 230px;
  //         }
  //       }
  //     }
  //   }
  // }

  .ant-table-content {
    table {
      .ant-table-thead {
        tr {
          th {
            padding: 6px 6px;
          }
        }
      }

      .ant-table-tbody {
        tr {
          td {
            padding: 6px 6px;
          }
        }

      }
    }
  }

  .noleftmenu_section {
    .dashboard-right-section {
      .form_outer_wrapper {
        padding: 0px;
        width: 100%;

        .dashboard-top-one {
          .dashboard-icon-center {
            height: 50px !important;
          }

          h2 {
            font-size: 15px;
            margin-top: 1.5em;
          }
        }

        .dashboard-top-two {
          .dashboard-icon-center {
            height: 50px !important;
          }

          h2 {
            font-size: 15px;
            margin-top: 1.5em;
          }
        }

        .dashboard-top-five {
          .dashboard-icon-center {
            height: 50px !important;
          }

          h2 {
            font-size: 15px;
            margin-top: 1.5em;
          }
        }

        .dashboard-top-three {
          .dashboard-icon-center {
            height: 50px !important;
          }

          h2 {
            font-size: 15px;
            margin-top: 1.5em;
          }
        }
      }
    }
  }

  .dashboard-bailiff {
    width: 100% !important;

    .tabletop-filter-options {
      text-align: left;
    }

    .MuiGrid-root.MuiGrid-grid-lg-12 {
      width: 100%;

      .ant-row {
        justify-content: normal;
        gap: 10px;

        .ant-col-lg-3 {
          max-width: 100%;

          .table-rows-select {
            width: 100%;
          }
        }
      }
    }
  }

  .creerunconstat-wrapper {
    .form_onlyscreenwrapper {
      width: 50% !important;
    }
  }

  .dashboard-right-section {
    .form_outer_wrapper {
      padding: 15px 30px;

      h2 {
        margin: 26px 15px;
        text-align: left;
      }

      .create_account {
        .ant-form-item {
          margin-bottom: 15px;
        }
      }
    }

    &.dashboard-bailiff .form_outer_wrapper h2:not(.toptitlewithunderline) {
      margin: 0.5em 0 10px 0;
    }

    .create_account {
      .profile_uploadimg_wrapper {
        position: inherit;

      }
    }
  }

  .seetheaccount-table {
    .ant-table-content {
      table {
        .ant-table-tbody {
          tr {
            td:nth-child(2) {
              min-width: 160px;
            }

            td:nth-child(3) {
              min-width: 160px;
            }

            td:nth-child(4) {
              min-width: 400px;
            }
          }
        }
      }
    }
  }

  .dashboard-right-section {
    .create_account {
      .ant-form-item-control-input-content {
        .ck.ck-editor {
          width: 330px;
        }
      }
    }
  }

  .only-for-web {
    display: none;
  }

  .only-for-mobile {
    display: block;
  }

  .dashboard-right-section {
    .form_outer_onlyscreenwrapper {
      .form_onlyscreenwrapper {
        .create_account {
          .ant-form-item {
            .ant-form-item-label {
              width: 35%;
            }
          }
        }
      }
    }
  }

  .mesconstats-table {
    .ant-table-content {
      table {
        .ant-table-tbody {
          tr {
            td:nth-child(1) {
              min-width: 60px;
            }

            td:nth-child(2) {
              min-width: 150px;
            }

            td:nth-child(3) {
              min-width: 330px;
            }

            td:nth-child(4) {
              min-width: 200px;
            }

            td:nth-child(5) {
              min-width: 200px;
            }

            td:nth-child(6) {
              min-width: 75px;
            }

            td:nth-child(7) {
              min-width: 75px;
            }

            td:nth-child(8) {
              min-width: 85px;
            }

            td:nth-child(9) {
              min-width: 100px;
            }
          }
        }
      }
    }
  }

  .constatsencours-table {
    .ant-table-content {
      table {
        .ant-table-tbody {
          tr {
            td {
              width: auto !important;
              min-width: auto !important;
            }
          }
        }
      }
    }
  }

  .constatsencours_wrapper {
    .tabletop-filter-section {
      .table-search-input {
        width: 100%;
      }
    }
  }

  .retrocessionadminreports_wrapper {
    width: 80%;

    .form_tableouter_wrapper {
      .table-toptable-layout {
        float: left;
        width: 400px;
        margin: 2em 0;
      }
    }
  }

  .retrocessionadminreports-table {
    .ant-table-content {
      table {
        .ant-table-thead {
          tr {
            th {
              padding: 6px 6px;
              width: auto !important;
              min-width: auto !important;
            }
          }
        }

        .ant-table-tbody {
          tr {
            td {
              padding: 6px 6px;
              width: auto !important;
              min-width: auto !important;
            }
          }

        }
      }
    }
  }
}

/*mobile vertical layout*/
@media only screen and (max-width: 767px) {
  // .ant-table table {
  //   overflow-x: auto !important;
  //   display: inline-block !important;
  // }

  // .constats-table .ant-table-content .ant-table-tbody tr td:nth-child(5){
  //   min-width: 150px;
  // }
  // .constats-table .ant-table-content .ant-table-tbody tr td:nth-child(6){
  //   min-width: 200px;
  // }
  // .constats-table .ant-table-content .ant-table-tbody tr td:nth-child(4){
  //   min-width: 200px;
  // }
  // .constats-table .ant-table-content .ant-table-tbody tr td:nth-child(3) {
  //   min-width: 155px;
  //   background: #f00;
  // } 
  .ant-table-content {
    table {
      .ant-table-tbody {
        tr {
          td:nth-child(3) {
            min-width: 170px;
          }

          td:nth-child(4) {
            min-width: 120px;
          }

          td:nth-child(5) {
            min-width: 330px;
          }

          td:nth-child(6) {
            min-width: 160px;
          }

          td:nth-child(7) {
            min-width: 230px;
          }
        }
      }
    }
  }

  .mesconstats-table {
    .ant-table-content {
      table {
        .ant-table-tbody {
          tr {
            td:nth-child(3) {
              min-width: 330px;
            }

            td:nth-child(4) {
              min-width: 160px;
            }

            td:nth-child(5) {
              min-width: 210px;
            }

            td:nth-child(6) {
              min-width: 80px;
            }

            td:nth-child(7) {
              min-width: 90px;
            }
          }
        }
      }
    }
  }

  .header {
    .dashboard_logo {
      float: left;
      width: 35%;
    }
  }

  .sidmenu_top_profile {
    img {
      width: 40px !important;
      height: 40px !important;
    }

    span {
      display: none;
    }
  }

  .ant-layout {
    .ant-layout-has-sider {
      .ant-layout-content {
        .table-rows-select {
          width: 100%;
        }

        .tabletop-filter-options {
          text-align: left;
          width: 100%;
          margin-top: 0px;
        }
      }
    }
  }

  // .ant-table table {
  //   overflow-x: auto !important;
  //   display: inline-block !important;
  // }

  // .dashboard-left-section {
  //   position: absolute;
  //   z-index: 1;
  // }

  .dashboard-right-section {
    float: left;
    width: 100%;
    padding: 15px 15px 15px 15px;
    // padding: 75px 15px 15px 15px;
  }

  .ant-layout-sider {
    .ant-layout-sider-children {
      height: 100%;
      border-right: 1px solid #ccc;
      position: fixed;
      background: #fff;
    }
  }

  .noleftmenu_section {
    .form_outer_wrapper {
      width: 100% !important;
      padding: 0px;

      .topfour-coloum {
        margin-top: 0em;

        .dashboard-top-one {
          h2 {
            font-size: 24px;
            font-weight: 500;
            color: #000000 65%;
          }
        }
      }

      .onlyscreen_template_wrapper {
        .form_onlyscreenwrapper {
          width: 90%;
        }
      }

      .dashboard-top-one {
        .dashboard-icon-center {
          height: 50px !important;
        }

        h2 {
          font-size: 15px;
          margin-top: 1.5em;
        }
      }

      .dashboard-top-two {
        .dashboard-icon-center {
          height: 50px !important;
        }

        h2 {
          font-size: 15px;
          margin-top: 1.5em;
        }
      }

      .dashboard-top-five {
        .dashboard-icon-center {
          height: 50px !important;
        }

        h2 {
          font-size: 15px;
          margin-top: 1.5em;
        }
      }

      .dashboard-top-three {
        .dashboard-icon-center {
          height: 50px !important;
        }

        h2 {
          font-size: 15px;
          margin-top: 1.5em;
        }
      }
    }

  }

  .creerunconstat-wrapper {
    .form_onlyscreenwrapper {
      width: 90% !important;
    }
  }

  .dashboard-right-section {
    .form_outer_wrapper {
      padding: 1.5rem;

      h2 {
        margin: 26px 15px;
        text-align: left;
      }

      .create_account {
        .ant-form-item {
          margin-bottom: 15px;
        }
      }
    }

    .create_account {
      .profile_uploadimg_wrapper {
        position: inherit;
      }
    }
  }

  .seetheaccount-table {
    .ant-table-content {
      table {
        .ant-table-tbody {
          tr {
            td:nth-child(2) {
              min-width: 160px;
            }

            td:nth-child(3) {
              min-width: 160px;
            }

            td:nth-child(4) {
              min-width: 400px;
            }
          }
        }
      }
    }
  }

  .dashboard-right-section {
    .create_account {
      .profile_uploadimg_wrapper {
        .uploadimage-bottom-select {
          width: 100%;
        }

        .validate-btn {
          width: 100%;
          margin-left: 0px !important;
        }
      }

      .ant-form-item-control-input-content {
        .ck.ck-editor {
          float: left;
          width: 100%;
        }
      }
    }


  }

  .only-for-web {
    display: none;
  }

  .only-for-mobile {
    display: block;
  }

  .ant-form-item-label>label {
    font-size: 12px;
  }

  .ant-layout-has-sider {
    margin-top: 4.2em !important;
  }

  .dashboard-left-section {
    // position: absolute;
    // z-index: 99;
    float: none;
    width: auto;
  }

  .ant-table {
    font-size: 12px;
  }

  .createreport_wrapper,
  .mybillsgailif_wrapper {
    width: 100% !important;
  }

  .creerunconstat-wrapper {
    .form_outer_onlyscreenwrapper {
      .form_onlyscreenwrapper {
        width: 90% !important;
      }

      .toptitlewithunderline {
        width: 90%;
      }
    }
  }

  .constatsencours_wrapper {
    .ant-table {
      .ant-table-content {
        overflow-x: scroll;
      }
    }

    .tabletop-filter-section {
      .table-search-input {
        width: 85%;
      }
    }
  }

  .ant-breadcrumb {
    text-align: left;
    margin-bottom: 1rem;
  }

  .dashboard-right-section {
    h2.pagetitle-table {
      margin-bottom: 1rem;
    }

    .form_outer_wrapper {
      margin-bottom: 15px;

      .toptitlewithunderline {
        margin: 0px 0px 1em 0px;
        width: 100%;
      }
    }
  }

  .createreport_wrapper {
    .createreport_form {
      width: 100%;
    }
  }

  .ant-layout-header {
    .styles_dashboard_logo__3EEje {
      width: 25%;
    }
  }

  .retrocessionadminreports_wrapper {
    .form_tableouter_wrapper {
      h2 {
        margin: 1em 0
      }

      .table-toptable-layout {
        width: 100%;
        margin: 2em 0;
        top: 0;
      }
    }
  }

  .chart-top-select-wrapper {
    h2 {
      margin: 1em 0;
    }

    .chart-top-select {
      margin-bottom: 2em;
    }
  }

  .createanews-wrapper {
    .form_outer_onlyscreenwrapper {
      h2 {
        width: 100%;
      }

      .form_onlyscreenwrapper {
        width: 100%;
        padding-top: 1em;

        .create_news {
          .news-headline {
            width: 100%;
          }
        }
      }
    }
  }

  .seetheaccount_wrapper_mask {
    .commonpagetitle_leftmenu {

      margin: 1em 0;

    }
  }

  .ant-upload.ant-upload-select-picture-card {
    .uploadimg-wrapper {
      bottom: 0em;
    }
  }

  .bailiffaccount_wrapper {
    .form_outer_wrapper {
      h2 {
        width: 100%;
        font-size: 14px;
        margin: 15px 0 15px 0px;
      }
    }
  }


  // #leftmenu-profileimg b{
  //   display: none !important;
  // }
  .ant-layout-header {
    float: left;
    width: 100% !important;
  }

  .profile_user .profile-dropdown span {
    display: none;
  }

  #dashboardlogo {
    float: left;
    width: 215px;
    text-align: right;
    padding-left: 35px;
  }

  #dashboardlogo img {
    width: 100px;
  }

  .dashboard-left-section .ant-layout-sider-zero-width-trigger {
    background: transparent;
    top: 12px;
    left: 9px;
    position: fixed;
  }

  .dashboard-left-section .ant-layout-sider-zero-width-trigger .anticon-bars svg {
    color: #000;
    font-size: 28px;
  }

  .ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed .ant-layout-sider-children {
    display: none;
  }

  // .ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-below .ant-layout-sider-children{
  //   display: block;
  // }
  #loginscreenwrapper {
    background-size: inherit !important;
    background-position: center center !important;
  }

  .profile_section .adminprofile-form {
    width: 100%;
    padding: 0px !important;
  }

  .profile-website {
    display: none;
  }

  .profile-mobile {
    display: block;
  }

  .ant-table-content {
    overflow: hidden;
  }

  // .dashboard-right-section .form_outer_wrapper{
  //   padding:0px;
  // }
  .adminprofile-form .ant-upload-select-picture-card .uploadimg-wrapper {
    left: 0;
  }

  .profile-mobile {
    float: left;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .dashboard-right-section .form_outer_onlyscreenwrapper {
    padding-bottom: 1rem;
  }

  .ant-layout .ant-layout-header {
    line-height: 44px;
  }

  // .topfour-coloum{
  //   display: none;
  // }

  .responsivecarousel {
    float: left;
    width: 100%;
    margin-bottom: 1rem;
  }

  .ant-carousel .slick-dots-bottom {
    display: none !important;
  }

  .responsivecarousel .dashboard-top-one {
    float: left;
    width: 100%;
    background: #ffffff;
    text-align: center;
    padding: 0em 1em;
    border-bottom: 3px solid #216594;
    border-radius: 2px 2px 2px 2px;
    -webkit-border-radius: 2px 2px 2px 2px;
    -moz-border-radius: 2px 2px 2px 2px;
  }

  .responsivecarousel .ant-row p {
    margin-bottom: 0px;
    text-align: right;
    font-size: 12px;
    color: #595959;
    margin-top: 8px;
  }

  .responsivecarousel .ant-row h2 {
    margin-bottom: 0px;
    text-align: right;
    font-size: 24px;
    color: #595959;
    font-weight: 500;
    margin-top: 0px;
  }

  .responsivecarousel .ant-row .dashboard-icon-center {
    display: flex;
    align-items: center;
    justify-content: left;
    height: 58px;
  }

  .responsivecarousel .dashboard-top-two {
    float: left;
    width: 100%;
    background: #ffffff;
    text-align: center;
    padding: 0em 1em;
    border-bottom: 3px solid #019BCD;
    border-radius: 2px 2px 2px 2px;
    -webkit-border-radius: 2px 2px 2px 2px;
    -moz-border-radius: 2px 2px 2px 2px;
  }

  .responsivecarousel .dashboard-top-five {
    float: left;
    width: 100%;
    background: #ffffff;
    text-align: center;
    padding: 0em 1em;
    border-bottom: 3px solid #216594;
    border-radius: 2px 2px 2px 2px;
    -webkit-border-radius: 2px 2px 2px 2px;
    -moz-border-radius: 2px 2px 2px 2px;
  }

  .responsivecarousel .dashboard-top-three {
    float: left;
    width: 100%;
    background: #ffffff;
    text-align: center;
    padding: 0em 1em;
    border-bottom: 3px solid #A96F9F;
    border-radius: 2px 2px 2px 2px;
    -webkit-border-radius: 2px 2px 2px 2px;
    -moz-border-radius: 2px 2px 2px 2px;
  }

  .responsivecarousel .dashboard-top-four {
    float: left;
    width: 100%;
    background: #ffffff;
    text-align: center;
    padding: 0em 1em;
    border-bottom: 3px solid #71518F;
    border-radius: 2px 2px 2px 2px;
    -webkit-border-radius: 2px 2px 2px 2px;
    -moz-border-radius: 2px 2px 2px 2px;
  }

  .responsivecarousel-wrapper {
    display: block;
  }

  .tabletop-filter-options {
    text-align: right;
  }

  .dashboard-left-section .ant-layout-sider .ant-layout-sider-children {
    position: fixed;
    z-index: 9;
  }

  .retrocessionadminreports_wrapper .ant-table-content table,
  .facturation-table .ant-table-content table {
    top: 0px;
  }

  .facturation_wrapper .table-toptable-layout {
    top: 0;
    width: 100%;
    margin-top: 2rem;
  }

  .tabletop-filter-section .ant-select {
    width: 100%;
  }

  .seetheaccount_wrapper_mask .tabletop_individual {
    width: 100%;
    margin-top: 2rem;
  }

  .onlyscreen-template.registration_wrapper {
    .form_outer_wrapper {
      padding: 0px;
      width: 100%;

      .onlyscreen_template_inner {
        padding: 1rem;
      }
    }
  }

  .create_account.rigisteruser .profile_uploadimg_wrapper .uploadimg-wrapper {
    margin-left: 0px;
  }

  .registration_wrapper .onlyscreen_template_inner h2 {
    width: 100%;
  }

  .dashboard-right-section .create_account .profile_uploadimg_wrapper .groupe-button .validate-btn {
    margin-top: 0px;
  }

  .dashboard-right-section .form_outer_wrapper .create_account .ant-form-item {
    margin-bottom: 0px;
  }

  .ckeditor_label_wrapper .ant-form-item-label {
    float: right;
    width: 100% !important;
  }

  .ckeditor_label_wrapper .ant-form-item {
    margin-bottom: 0px;
  }

  .secretarys_form .ant-upload-select-picture-card .uploadimg-wrapper {
    left: 0em;
  }

  .creerungroupe_form {
    width: 100% !important;
  }

  .creerungroupe_form .creerungroupe_form_btn {
    width: 100%;
    float: right;
    text-align: left;
  }

  .viewnews_wrapper .form_viewnews_wrapper .tabletop-filter-section .table-search-input {
    width: 90%;
  }

  .userprofile-layout-wrapper .ant-layout.ant-layout-has-sider .ant-layout-sider {
    width: inherit;
  }

  .secretarymybills_wrapper .ant-layout.ant-layout-has-sider .ant-layout-sider {
    width: inherit;
  }

  .secretaryprofile-form .inputfield-middle {
    width: 100%;
  }

  .secretarymybills_wrapper ul.ant-menu-light li.ant-menu-item {
    float: left;
    width: 33% !important;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 10px !important;
  }

  .userprofile-layout-wrapper ul.ant-menu-light li.ant-menu-item {
    float: left;
    width: 33% !important;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 10px !important;
  }

  // aside.ant-layout-sider{
  //   flex: inherit !important;
  //   max-width: inherit !important;
  //   min-width: inherit !important;
  //   width: inherit !important;
  // }
  aside.ant-layout-sider {
    position: absolute;
    height: 100vh;
  }

  .ant-layout-sider .ant-layout-sider-children {
    //height: auto !important;
    width: 100% !important;
    border: medium none !important;
    position: absolute !important;
  }

  .profile_bailiff_wrapper .form_outer_onlyscreenwrapper {
    margin-top: 5rem;
  }

  .profile_bailiff_wrapper .form_onlyscreenwrapper {
    width: 100% !important;
  }

  .profile_bailiff_wrapper .form_onlyscreenwrapper .ant-layout-has-sider {
    margin-top: 0 !important;
    flex-direction: column !important;
  }

  .userprofile-layout-wrapper .ant-layout.ant-layout-has-sider .ant-layout {
    background: #ffffff;
    margin-top: 60px;
  }

  .secretarymybills_wrapper .ant-layout.ant-layout-has-sider .ant-layout {
    background: #ffffff;
    margin-top: 60px;
  }

  .userprofile-layout-wrapper .ant-menu-inline .ant-menu-item::after {
    border: medium none !important;
  }

  .secretarymybills_wrapper .ant-layout-sider .ant-layout-sider-children {
    width: inherit !important;
  }

  .secretarymybills_wrapper .ant-layout.ant-layout-has-sider {
    flex-direction: column !important;
  }

  .form_tableouter_wrapper {
    flex-direction: column !important;
  }

  .createanalert-wrapper .createanalert-mask {
    width: 100%;
  }

  .createanalert-wrapper {
    padding: 1rem;
  }

  .bailiffprofile-form .inputfield-middle {
    width: 100%;
  }

  .noleftmenu_section .topfour-coloum .dashboard-top-three .ant-row h2,
  .noleftmenu_section .topfour-coloum .dashboard-top-five .ant-row h2,
  .noleftmenu_section .form_outer_wrapper .topfour-coloum .dashboard-top-one h2,
  .noleftmenu_section .topfour-coloum .dashboard-top-two .ant-row h2 {
    margin: 0px;
    padding: 0px;
  }

  .commontopfourwrapper h2 a {
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    margin-top: 8px !important;
  }

  .noleftmenu_section .topfour-coloum .dashboard-top-two,
  .noleftmenu_section .topfour-coloum .dashboard-top-five,
  .noleftmenu_section .topfour-coloum .dashboard-top-three,
  .noleftmenu_section .topfour-coloum .dashboard-top-one {
    padding: 0px 0.5rem;
    min-height: 101px;
  }

  .responsivelayout-tiles {
    padding: 7px !important;
  }

  .dashboard-top-one .dashboard-icon-center,
  .dashboard-top-two .dashboard-icon-center,
  .dashboard-top-five .dashboard-icon-center,
  .dashboard-top-three .dashboard-icon-center {
    display: flex;
    align-items: center;
    justify-content: center !important;
  }

  .bailiffdashboard .topfour-coloum,
  .createreportbaillif_wrapper .topfour-coloum {
    margin-top: 4em !important;
  }

  .dashboard-right-section .createreportbaillif_wrapper {
    width: 100% !important;
  }

  .dashboard-right-section .topfour-coloum .dashboard-top-three .ant-row h2,
  .dashboard-right-section .topfour-coloum .dashboard-top-five .ant-row h2,
  .dashboard-right-section .topfour-coloum .dashboard-top-one .ant-row h2,
  .dashboard-right-section .topfour-coloum .dashboard-top-two .ant-row h2 {
    margin: 0px;
  }

  .dashboard-right-section .topfour-coloum .dashboard-top-one,
  .dashboard-right-section .topfour-coloum .dashboard-top-two,
  .dashboard-right-section .topfour-coloum .dashboard-top-five,
  .dashboard-right-section .topfour-coloum .dashboard-top-three {
    padding: 0px 0.5rem;
    min-height: 101px;
  }

  .createreport_wrapper .bailiffdas-bord-mask .dashboard-icon-center {
    height: 50px !important;
  }

  // .dashboard-right-section  .createreportbaillif_wrapper h2{
  //   margin: 0px 0px 5px 0px !important;
  // }
  .form_outer_wrapper.bailiffdashboard {
    padding: 0.5rem !important;
  }

  .form_outer_wrapper.createreportbaillif_wrapper {
    padding: 0.5rem !important;
  }

  .bailiffdashboard .form_tableouter_wrapper h2 {
    margin: 26px 0px;
  }

  .mybillsgailif_wrapper .form_tableouter_wrapper h2 {
    margin: 26px 0px;
  }

  // .billsgailif_wrapper{

  // }
  .profile_bailiff_wrapper ul.ant-menu-light li.ant-menu-item {
    width: 50% !important;
    text-align: center;
  }

  .form_outer_wrapper.billsgailif_wrapper {
    padding: 0.5rem !important;
  }

  .topfour-coloum.commontopfourwrapper.bailiffdas-bord-mask {
    margin-top: 5em !important;
  }

  .constatsencours_wrapper .topfour-coloum {
    display: none;
  }

  .secretarydashboard .commontopfourwrapper {
    background: #000;
    display: none;
  }

  .secretarydashboard .form_tableouter_wrapper {
    margin-top: 4rem;
  }

  .secretarydashboard .responsivecarousel-wrapper .dashboard-top-three h2,
  .secretarydashboard .responsivecarousel-wrapper .dashboard-top-two h2,
  .secretarydashboard .responsivecarousel-wrapper .dashboard-top-five h2,
  .secretarydashboard .responsivecarousel-wrapper .dashboard-top-one h2,
  .secretarydashboard .responsivecarousel-wrapper .dashboard-top-four h2 {
    font-size: 24px;
    color: #595959;
    font-weight: 500;
    margin: 0.5rem 0 0 0;
  }

  .secretarytabletop-filters {
    flex-direction: column;
  }

  .form_outer_wrapper.secretarydashboard {
    padding: 0px;
  }

  .secretarytabletop-search {
    margin: 1rem 0px 0px 0px !important;
  }

  .profileimgresponsive {
    display: block;
  }

  .profileimgweb {
    display: none;
  }

  .secretaryprofile-form .ant-upload-select-picture-card .ant-upload .uploadimg-wrapper {
    margin: 0 auto;
  }

  .profilesecretarypro aside.ant-layout-sider {
    background: #ffffff !important;
    float: left !important;
    width: 100% !important;
    max-width: inherit !important;
    min-width: inherit !important;
  }

  .profilesecretarypro ul.ant-menu-light li.ant-menu-item {
    width: 33% !important;
  }

  .profilesecretarypro ul {
    border-bottom: 15px solid #f0f2f5 !important;
  }

  .profilesecretarypro .form_outer_wrapper {
    padding: 0px;
  }

  .profilesecretarypro .form_outer_onlyscreenwrapper {
    margin-top: 5rem;
  }

  .profilesecretarypro .userprofile-layout-wrapper .ant-layout-header {
    display: none;
  }

  .secaritertcal-mask aside.ant-layout-sider {
    background: #ffffff !important;
    float: left !important;
    width: 100% !important;
    max-width: inherit !important;
    min-width: inherit !important;
  }

  .secaritertcal-mask ul.ant-menu-light li.ant-menu-item {
    width: 33% !important;
  }

  .secaritertcal-mask ul {
    border-bottom: 15px solid #f0f2f5 !important;
  }

  .secaritertcal-mask .form_outer_wrapper {
    padding: 0px;
  }

  .secaritertcal-mask .form_outer_onlyscreenwrapper {
    margin-top: 5rem;
  }

  .secaritertcal-mask .userprofile-layout-wrapper .ant-layout-header {
    display: none;
  }

  .ant-picker-calendar-header .ant-picker-calendar-mode-switch {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1.5rem 0 1rem 0;
  }

  .ant-picker-calendar-header .ant-picker-calendar-mode-switch label:first-child {
    width: 45%;
  }

  .ant-picker-calendar-header .ant-picker-calendar-mode-switch label:last-child {
    width: 45%;
  }

  .secretarymybills_wrapper {
    float: left;
    width: 100%;
  }

  .secretarymybills_wrapper .ant-layout.ant-layout-has-sider .ant-layout-sider {
    background: #ffffff !important;
    float: left !important;
    height: auto;
    width: 100% !important;
    max-width: inherit !important;
    min-width: inherit !important;
  }

  .secretarymybills_wrapper .billssecretaries-table .ant-table-content {
    overflow: hidden;
  }

  .secretarymybills_wrapper .billssecretaries-table .ant-table-content table .ant-table-tbody tr td,
  .secretarymybills_wrapper .billssecretaries-table .ant-table-content table .ant-table-thead tr th {
    padding: 10px 5px;
  }

  .secretarymybills_wrapper .billssecretaries-table .ant-table-content table .ant-table-thead tr th:first-child,
  .secretarymybills_wrapper .billssecretaries-table .ant-table-content table .ant-table-thead tr th:nth-child(2),
  .secretarymybills_wrapper .billssecretaries-table .ant-table-content table .ant-table-thead tr th:nth-child(3),
  .secretarymybills_wrapper .billssecretaries-table .ant-table-content table .ant-table-thead tr th:nth-child(4),
  .secretarymybills_wrapper .billssecretaries-table .ant-table-content table .ant-table-thead tr th:nth-child(5),
  .secretarymybills_wrapper .billssecretaries-table .ant-table-content table .ant-table-thead tr th:last-child,

  .secretarymybills_wrapper .billssecretaries-table .ant-table-content table .ant-table-tbody tr td:first-child,
  .secretarymybills_wrapper .billssecretaries-table .ant-table-content table .ant-table-tbody tr td:nth-child(2),
  .secretarymybills_wrapper .billssecretaries-table .ant-table-content table .ant-table-tbody tr td:nth-child(3),
  .secretarymybills_wrapper .billssecretaries-table .ant-table-content table .ant-table-tbody tr td:nth-child(4),
  .secretarymybills_wrapper .billssecretaries-table .ant-table-content table .ant-table-tbody tr td:nth-child(5),
  .secretarymybills_wrapper .billssecretaries-table .ant-table-content table .ant-table-tbody tr td:last-child {
    text-align: center;
    width: inherit !important;
    min-width: inherit !important;
  }

  .secretarymybills_wrapper .userprofile-layout-wrapper .ant-layout.ant-layout-has-sider .ant-layout .ant-layout-content {
    padding: 0px;
  }

  .secretarymybills_wrapper .form_outer_onlyscreenwrapper {
    background: transparent;
  }

  .secretarymybills_wrapper .form_outer_wrapper {
    padding: 0px;
  }

  .secretarymybills_wrapper .userprofile-layout-wrapper ul.ant-menu {
    width: 100%;
  }

  .secretarymybills_wrapper .form_onlyscreenwrapper {
    width: 100% !important;
    float: left;
  }

  // .secretarymybills_wrapper .ant-layout-sider .ant-layout-sider-children {
  //   width: unset !important;
  // }
  .secretarymybills_wrapper .userprofile-layout-wrapper .ant-layout.ant-layout-has-sider {
    margin-top: 4rem !important;
  }

  .secretarymybills_wrapper .tabletop-filter-section {
    padding: 10px;
  }

  .secretarymybills_wrapper .billssecretaries-table table {
    padding: 10px;
  }

  .secretarydashboard-reaponsive {
    margin-top: 5rem !important;
  }

  .secretarydashboard-reaponsive .form_tableouter_wrapper {
    margin-top: 2rem !important;
  }

  .retrocessionsendbill_wrapper .form_tableouter_wrapper .table-toptable-layout {
    width: 100%;
    margin: 2rem 0;
  }

  .retrocessionsendbill_wrapper .tabletop-filter-section {
    float: left;
  }

  .profile_bailiff_wrapper .ant-layout.ant-layout-has-sider .ant-layout-sider {
    background: #ffffff !important;
    float: left !important;
    height: auto;
    width: 100% !important;
    max-width: inherit !important;
    min-width: inherit !important;
  }

  .bailiffprofile-form .ant-upload-select-picture-card .ant-upload {
    justify-content: center;
  }

  .bailiffprofile-form .bailiffprofile-address {
    position: relative;
    top: 0px;
  }

  li.ant-menu-item-selected {
    border: medium none !important;
  }

  .profile_bailiff_wrapper .form_outer_wrapper {
    padding: 0;
  }

  .mybillsgailif_wrapper .mybillsbailiff-table .ant-table-content table .ant-table-tbody tr td,
  .mybillsgailif_wrapper .mybillsbailiff-table .ant-table-content table .ant-table-thead tr th {
    padding: 10px 5px;
  }

  .mybillsgailif_wrapper .mybillsbailiff-table .ant-table-content table .ant-table-thead tr th:first-child,
  .mybillsgailif_wrapper .mybillsbailiff-table .ant-table-content table .ant-table-thead tr th:nth-child(2),
  .mybillsgailif_wrapper .mybillsbailiff-table .ant-table-content table .ant-table-thead tr th:nth-child(3),
  .mybillsgailif_wrapper .mybillsbailiff-table .ant-table-content table .ant-table-thead tr th:nth-child(4),
  .mybillsgailif_wrapper .mybillsbailiff-table .ant-table-content table .ant-table-thead tr th:nth-child(5),
  .mybillsgailif_wrapper .mybillsbailiff-table .ant-table-content table .ant-table-thead tr th:last-child,

  .mybillsgailif_wrapper .mybillsbailiff-table .ant-table-content table .ant-table-tbody tr td:first-child,
  .mybillsgailif_wrapper .mybillsbailiff-table .ant-table-content table .ant-table-tbody tr td:nth-child(2),
  .mybillsgailif_wrapper .mybillsbailiff-table .ant-table-content table .ant-table-tbody tr td:nth-child(3),
  .mybillsgailif_wrapper .mybillsbailiff-table .ant-table-content table .ant-table-tbody tr td:nth-child(4),
  .mybillsgailif_wrapper .mybillsbailiff-table .ant-table-content table .ant-table-tbody tr td:nth-child(5),
  .mybillsgailif_wrapper .mybillsbailiff-table .ant-table-content table .ant-table-tbody tr td:last-child {
    text-align: center;
    width: inherit !important;
    min-width: inherit !important;
  }

  h2.createreportit-responsive {
    float: left;
    width: 100%;
    margin: 15px 0px 15px 0px !important;
  }

  .bailiffdashboard .table-search-input.responsiveflaot {
    position: relative;
    right: -112px;
  }

  .bailiffdashboard .nouvelle-btn {
    float: right;
    width: 100%;
  }

  .ant-notification-notice-description {
    float: left;
    width: 94%;
    //border: 1px solid springgreen;
    background: #ffffff;
  }

  .selectuertime-resp {
    float: left;
    width: 100%;
  }

  .adminaccount_form .ant-upload-select-picture-card .uploadimg-wrapper {
    left: 0;
  }

  .profile_section .userprofile-layout-wrapper {
    padding: 20px;
  }

  .profile_section .form_outer_onlyscreenwrapper {
    margin-top: 1rem;
  }

  .retrocessionadminreports-modal .comment_wrapper {
    padding: 0px;
  }

  .ant-modal-content .ant-modal-body {
    padding: 15px 15px;
  }

  .notificationtitle-resp {
    text-align: center;
  }

  .notificationtitle-resp .hidetitleresp {
    display: none;
  }

  ul.ant-dropdown-menu li.ant-dropdown-menu-item:hover {
    background: #E4F5FD;
  }
}

// .ant-form-item-label {
//   label {
//     white-space: normal;
//   }
// }

// .button-news {
//   position: absolute;
//   width: 100%;
//   bottom: 0;
// }

/*mobile horizontal layout*/
@media (min-width: 480px) and (max-width: 766px) {}

@media only screen and (max-width : 736px) {}

@media only screen and (max-width : 667px) {}

@media only screen and (max-width : 480px) {}

@media only screen and (max-width : 414px) {}

@media only screen and (max-width : 375px) {}

@media only screen and (max-width : 360px) {}

@media only screen and (max-width : 320px) {}
